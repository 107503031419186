import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation DeleteTeamInvitationMutation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      deletedId
    }
  }
`;

const commit = function(id, onSuccess) {
  const variables = {
    input: {
      id,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (store) => {
      const payload = store.getRootField('deleteInvitation');
      const teamProxy = store
        .getRoot()
        ?.getLinkedRecord('viewer')
        ?.getLinkedRecord('team');
      const conn = ConnectionHandler.getConnection(
        teamProxy,
        'Team_invitations',
      );
      if (conn) {
        return ConnectionHandler.deleteNode(
          conn,
          payload.getValue('deletedId'),
        );
      }
    },
  });
};

export default { commit };
