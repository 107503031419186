/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SendMutation from '../mutations/SendMutation';
import Grid from '@material-ui/core/Grid';

import APIHOST from '../const';
import $ from 'jquery';
import { createRequestError } from '../RelayEnv';
import defaultMessage from '../defaultMessage';
import Store from './Store';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  code: {
    marginTop: theme.spacing(1),
  },
});

class SimpleCurl extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSend = this.onSend.bind(this);
    this.copy = this.copy.bind(this);
    this.onSync = this.onSync.bind(this);
    this.state = {
      response: null,
      message: defaultMessage(),
      copied: false,
      sync: false,
      edit: false,
      activeTab: 0,
    };
  }

  onEdit(e) {
    return this.setState({
      edit: !this.state.edit,
    });
  }

  async onSend(e) {
    e.preventDefault();

    if (this.current != null) {
      this.current.abort();
    }

    if (this.props.onSuccess == null) {
      this.setState({
        response: null,
      });
    }
    if (this.props.onSend != null) {
      this.props.onSend();
    }

    const headers = {};

    headers['Authorization'] = 'Bearer ' + this.props.accessToken;

    const msg = {
      id: this.props.id,
      title: this.state.message.title,
      body: this.state.message.body,
      action1: this.state.message.action0.enabled
        ? this.state.message.action0.text
        : null,
      action2: this.state.message.action1.enabled
        ? this.state.message.action1.text
        : null,
      reply: this.state.message.action2.enabled
        ? this.state.message.action2.text
        : null,
    };

    if (!this.props.onResponse) {
      return SendMutation.commit(this.props.id, msg, this.onSuccess);
    } else {
      return (this.current = $.ajax({
        url: `${APIHOST}/v1/send_sync`,
        headers,
        type: 'POST',
        data: msg,
        success: (resp) => {
          if (this.props.onResponse != null) {
            this.props.onResponse(resp);
            return;
          }
          return this.setState({
            response: resp,
          });
        },
        error: (res, status, error) => {
          let err;
          try {
            const json = JSON.parse(res.responseText);
            err = createRequestError(status, json);
            err = err.message;
          } catch (e) {
            err = res.responseText || error;
          }

          if (this.props.onResponse != null) {
            this.props.onResponse(null, err);
          } else {
            Store.onFailure(err);
          }
          return this.setState({
            responseLoading: false,
          });
        },
      }));
    }
  }

  copy(e) {
    let range;
    e.preventDefault();
    let text = `curl ${APIHOST}/v1/${this.state.sync ? 'send_sync' : 'send'}
-u ${this.props.accessToken}:
-d 'id=${this.props.id}'
-d 'title=${this.state.message.title}'
-d 'body=${this.state.message.body}'`;
    if (this.state.message.action0.enabled) {
      text += ` -d 'action1=${this.state.message.action0.text}'`;
    }
    if (this.state.message.action1.enabled) {
      text += ` -d 'action2=${this.state.message.action1.text}'`;
    }
    if (this.state.message.action2.enabled) {
      text += ` -d 'reply=${this.state.message.action2.text}'`;
    }
    const element = document.createElement('a');
    element.setAttribute('href', text);
    element.textContent = text;
    document.body.appendChild(element);
    if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      range = document.createRange();
      range.selectNode(element);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }

    document.execCommand('copy');
    element.remove();
    return this.setState({
      copied: true,
    });
  }

  onSync(e) {
    return this.setState({
      sync: e.target.checked,
    });
  }

  handleChange(name) {
    return (event) => {
      return this.setState({ ...this.state, [name]: event.target.checked });
    };
  }

  actionChecked(action) {
    return (e) => {
      const foo = Object.assign(this.state.message, {});
      foo[action]['enabled'] = !this.state.message[action]['enabled'];
      return this.setState({
        message: foo,
      });
    };
  }

  messageField(field) {
    return (e) => {
      const foo = Object.assign(this.state.message, {});
      foo[field] = e.target.value;
      return this.setState({
        message: foo,
      });
    };
  }

  actionField(field) {
    return (e) => {
      const foo = Object.assign(this.state.message, {});
      foo[field].text = e.target.value;
      return this.setState({
        message: foo,
      });
    };
  }

  render() {
    const { classes } = this.props;
    const message = {
      actions: [
        {
          id: 1,
          value: 'Action 1',
        },
        {
          id: 2,
          value: 'Action 2',
        },
        {
          id: 3,
          value: 'Reply',
        },
      ],
    };
    console.log('message', message, 'state', this.state);
    return (
      <>
        <Grid container>
          {this.state.edit || this.props.width !== 'xs' ? (
            <Grid item md={5} xs={12}>
              <TextField
                style={{ marginRight: 10 }}
                onChange={this.messageField('title')}
                label="title"
                type="title"
                value={this.state.message.title}
                required
              />
              <TextField
                onChange={this.messageField('body')}
                label="body"
                value={this.state.message.body}
                type="body"
              />
              {message.actions.map((action, index) => {
                const actionNum = 'action' + index;
                return (
                  <div key={action.id} className={classes.row}>
                    <TextField
                      onChange={this.actionField(actionNum)}
                      label={action.value}
                      value={this.state.message[actionNum].text}
                    />
                    <Checkbox
                      onChange={this.actionChecked(actionNum)}
                      checked={this.state.message[actionNum].enabled}
                    />
                  </div>
                );
              })}
              <br />
              {this.props.width !== 'xs' ? (
                <Button onClick={this.onSend} color="primary">
                  Send
                </Button>
              ) : null}
            </Grid>
          ) : null}
          {this.props.width === 'xs' ? (
            <>
              <Button onClick={this.onEdit} color="primary">
                Edit
              </Button>
              <Button onClick={this.onSend} color="primary">
                Send
              </Button>
            </>
          ) : null}
          <Grid item md={7} xs={12}>
            {this.state.edit ||
              (this.props.width !== 'xs' && (
                <Tabs
                  value={this.state.activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                  onChange={(e, tabId) => this.setState({ activeTab: tabId })}
                >
                  <Tab value={0} label="Preview" />
                  <Tab value={1} label="Code" />
                </Tabs>
              ))}
            {this.state.activeTab == 0 && (
              <div style={{ flex: 1, padding: 5 }}>
                <Typography
                  style={{ fontSize: 12, color: '#aaa', marginBottom: 6 }}
                >
                  Message preview
                </Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {this.state.message.title}
                </Typography>
                <Typography>{this.state.message.body}</Typography>
                <div
                  style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}
                >
                  {message.actions.map((action, index) => {
                    const actionNum = 'action' + index;
                    if (!this.state.message[actionNum].enabled) {
                      return null;
                    }
                    return (
                      <Button
                        key={actionNum}
                        color="primary"
                        style={{
                          paddingBottom: 5,
                          paddingRight: 10,
                          paddingTop: 0,
                          margin: 0,
                          height: 30,
                        }}
                      >
                        {this.state.message[actionNum].text}
                      </Button>
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.activeTab == 1 && (
              <div className={classes.code}>
                {this.state.edit || this.props.width !== 'xs' ? (
                  <React.Fragment>
                    <code>
                      <div className="method-example-request include-prompt">
                        <div
                          className=" language-bash"
                          style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                            fontSize: '13px',
                            fontWeight: 400,
                            content: '$ ',
                          }}
                        >
                          curl {APIHOST}/v1/
                          {this.state.sync ? 'send_sync' : 'send'} \{'\n  '}
                          <span className="token api-key">
                            <span className="token request-flag">-u</span>{' '}
                            {this.props.accessToken}
                          </span>
                          : \{'\n  '}
                          <span className="token">
                            <span className="token request-flag">-d</span>{' '}
                            {`id=${this.props.id}`}
                          </span>{' '}
                          \{'\n  '}
                          <span className="token">
                            <span className="token request-flag">-d</span>{' '}
                            {`'title=${this.state.message.title}'`}
                          </span>{' '}
                          \{'\n  '}
                          <span className="token">
                            <span className="token request-flag">-d</span>{' '}
                            {`'body=${this.state.message.body}'`}
                          </span>
                          {this.state.message.action0.enabled ||
                          this.state.message.action1.enabled ||
                          this.state.message.action2.enabled ? (
                            <React.Fragment>&nbsp;\{'\n  '}</React.Fragment>
                          ) : null}
                          {this.state.message.action0.enabled ? (
                            <React.Fragment>
                              <span className="token">
                                <span className="token request-flag">-d</span>{' '}
                                {`'action1=${this.state.message.action0.text}'`}
                              </span>
                              {this.state.message.action1.enabled ||
                              this.state.message.action2.enabled ? (
                                <React.Fragment>&nbsp;\{'\n  '}</React.Fragment>
                              ) : null}
                            </React.Fragment>
                          ) : null}
                          {this.state.message.action1.enabled ? (
                            <React.Fragment>
                              <span className="token">
                                <span className="token request-flag">-d</span>{' '}
                                {`'action2=${this.state.message.action1.text}'`}
                              </span>
                              {this.state.message.action2.enabled ? (
                                <React.Fragment>&nbsp;\{'\n  '}</React.Fragment>
                              ) : null}
                            </React.Fragment>
                          ) : null}
                          {this.state.message.action2.enabled ? (
                            <React.Fragment>
                              <span className="token">
                                <span className="token request-flag">-d</span>{' '}
                                {`'reply=${this.state.message.action2.text}'`}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </div>
                      </div>
                    </code>
                  </React.Fragment>
                ) : null}
                {this.state.edit || this.props.width !== 'xs' ? (
                  <Button onClick={this.copy} color="primary">
                    {this.state.copied ? 'Copied' : 'Copy'}
                  </Button>
                ) : null}
                {this.state.edit || this.props.width !== 'xs' ? (
                  <React.Fragment>
                    <FormControlLabel
                      style={{ marginLeft: 10 }}
                      control={
                        <Checkbox
                          onChange={this.onSync}
                          checked={this.state.sync}
                        />
                      }
                      label="Synchronous mode"
                    />
                    {this.state.sync ? (
                      <div>
                        Caution: A synchronous connection can be terminated at
                        anytime.{' '}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://pushback.io/docs/sync/"
                        >
                          Learn more
                        </a>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
                <div>
                  Visit{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://pushback.io/docs/getting-started/"
                  >
                    Getting Started
                  </a>{' '}
                  for other language examples
                </div>
              </div>
            )}
          </Grid>
          <Grid item md={7} xs={12}></Grid>
        </Grid>
      </>
    );
  }
}

export default withWidth()(withStyles(styles, { withTheme: true })(SimpleCurl));
