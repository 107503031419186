/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Card from './Card';
import Store from './Store';
import useCurrentUser from './useCurrentUser';
import UpdateUserMutation from '../mutations/UpdateUserMutation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

let Profile = ({ viewer }) => {
  const classes = useStyles();
  const [displayName, setDisplayName] = React.useState(viewer.displayName);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let devices = () => {
    return viewer.devices.edges.map((edge) => {
      return (
        <div key={edge.node.id}>
          <div>
            <div>
              {' '}
              {edge.node.id} {edge.node.name}
            </div>
          </div>
        </div>
      );
    });
  };

  let onSave = () => {
    UpdateUserMutation.commit(displayName, null, null, () => {
      Store.setSuccess('Saved');
    });
  };

  const hasDevice = viewer.devices.edges.length > 0;
  const [currentUser] = useCurrentUser();

  return (
    <Grid className={classes.root} container spacing={2}>
      {currentUser.isAnonymous && (
        <Grid item xs={12}>
          <Card>
            <div>
              Finish setting up your account and devices
              <div>
                <Button component={Link} to="/finish_setup" color="primary">
                  Finish setup
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <Typography variant="h2" className={classes.title}>
            Profile
          </Typography>
          <table cellSpacing={5}>
            <tbody>
              <tr>
                <td>User ID</td>
                <td>{viewer.id}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{viewer.email}</td>
              </tr>
              <tr>
                <td>Display Name</td>
                <td>
                  <TextField
                    fullWidth
                    onChange={(e) => setDisplayName(e.target.value)}
                    autoComplete="email"
                    type="email"
                    required
                    value={displayName}
                    style={{ width: '240px' }}
                  />
                </td>
              </tr>
              <tr>
                <td>Access Token</td>
                <td>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                  >
                    <Input
                      id="standard-adornment-password"
                      readOnly
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      value={viewer.accessToken.secret}
                      labelWidth={50}
                      style={{ minWidth: 300 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            className={classes.send}
            color="primary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>
          {viewer.subscribed === 'subscribed' && (
            <Grid item xs={12}>
              <Card>
                <Typography variant="h2">Subscribed</Typography>
                <Typography>
                  You are currently subscribed and get unlimited messages. To
                  unsubscribe email support@pushback.io
                </Typography>
              </Card>
            </Grid>
          )}
          {hasDevice && (
            <div>
              <Typography variant="h2">Devices</Typography>
              {devices()}
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

Profile = createFragmentContainer(Profile, {
  viewer: graphql`
    fragment Profile_viewer on User {
      id
      email
      displayName
      accessToken {
        secret
      }
      subscribed
      devices(first: 100) @connection(key: "Profile_devices") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});

export default Profile;
