/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { Switch, Route } from 'react-router';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';

import NavBar from './NavBar';
import Channels from './Channels';
import Home from './Home';
import Team from './Team';
import Profile from './Profile';
import Subscribe from './Subscribe';
import CreateChannel from './CreateChannel';
import ChannelView from './ChannelView';
import FinishSetup from './FinishSetup';
import ChannelCallbackGraphiQLContainer from './ChannelCallbackGraphiql';
import TeamCallbackGraphiQLContainer from './TeamCallbackGraphiql';
import Graphiql from './Graphiql';
import DeviceSetup from './device_setup/DeviceSetup';

import MessageSent from '../subscriptions/MessageSent';
import ReplySent from '../subscriptions/ReplySent';
import { STRIPEKEY } from '../const';

const drawerWidth = 240;
const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },

  content: {
    position: 'relative',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      padding: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.drawerToggle = this.drawerToggle.bind(this);
    this.state = {
      foo: 'bar',
      mobileOpen: false,
    };
  }

  drawerToggle() {
    return this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  }

  componentDidMount() {
    this.dispose2 = ReplySent.subscribe(this.props.viewer);
    this.dispose = MessageSent.subscribe(
      this.props.viewer.id,
      this.props.viewer.id,
    );
    // Preload stripe
    window.Stripe(STRIPEKEY);
  }

  componentWillUnmount() {
    if (this.dispose != null) {
      this.dispose.dispose();
    }
    if (this.dispose2 != null) this.dispose2.dispose();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Switch>
          <Route
            path="/channel/create"
            render={(routeProps) => {
              return (
                <CreateChannel viewer={this.props.viewer} {...routeProps} />
              );
            }}
          />
          <Route
            path="/graphiql"
            render={(channelProps) => {
              return <Graphiql viewer={this.props.viewer} />;
            }}
          />
          <Route
            path="/"
            render={(moreProps) => {
              return (
                <div className={classes.container}>
                  <NavBar drawerToggle={this.drawerToggle} />
                  <Channels
                    drawerToggle={this.drawerToggle}
                    mobileOpen={this.state.mobileOpen}
                    viewer={this.props.viewer}
                  />
                  <div className={classes.content}>
                    <Switch>
                      <Route
                        path="/subscribe"
                        render={(channelProps) => {
                          return (
                            <Subscribe
                              viewer={this.props.viewer}
                              {...channelProps}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/channels/:channel_id"
                        render={(channelProps) => {
                          return <ChannelView {...channelProps} />;
                        }}
                      />
                      <Route
                        path="/finish_setup"
                        render={(channelProps) => {
                          return <FinishSetup viewer={this.props.viewer} />;
                        }}
                      />
                      <Route
                        path="/device_setup"
                        render={(channelProps) => {
                          return <DeviceSetup viewer={this.props.viewer} />;
                        }}
                      />
                      <Route
                        path="/channel_callback/:channel_id"
                        render={(channelProps) => {
                          return (
                            <ChannelCallbackGraphiQLContainer
                              viewer={this.props.viewer}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/profile"
                        render={(tokenProps) => {
                          return <Profile viewer={this.props.viewer} />;
                        }}
                      />
                      <Route
                        path="/team"
                        render={(tokenProps) => {
                          return <Team />;
                        }}
                      />
                      <Route
                        path="/team_callback"
                        render={(channelProps) => {
                          return (
                            <TeamCallbackGraphiQLContainer
                              viewer={this.props.viewer}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/"
                        render={(tokenProps) => {
                          return <Home viewer={this.props.viewer} />;
                        }}
                      />
                    </Switch>
                  </div>
                </div>
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

Main = createFragmentContainer(Main, {
  viewer: graphql`
    fragment Main_viewer on User {
      id
      ...Channels_viewer
      ...Graphiql_viewer
      ...Home_viewer
      ...Profile_viewer
      ...Subscribe_viewer
      ...FinishSetup_viewer
    }
  `,
});

export default withStyles(styles, { withTheme: true })(Main);
