/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChannelCallbackGraphiqlQueryVariables = {|
  channel_id: string
|};
export type ChannelCallbackGraphiqlQueryResponse = {|
  +viewer: {|
    +id: string
  |},
  +node: ?{|
    +id: string,
    +callbackQuery?: ?string,
  |},
|};
export type ChannelCallbackGraphiqlQuery = {|
  variables: ChannelCallbackGraphiqlQueryVariables,
  response: ChannelCallbackGraphiqlQueryResponse,
|};
*/


/*
query ChannelCallbackGraphiqlQuery(
  $channel_id: ID!
) {
  viewer {
    id
  }
  node(id: $channel_id) {
    __typename
    id
    ... on OwnedChannel {
      id
      callbackQuery
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "channel_id",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "channel_id"
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callbackQuery",
      "storageKey": null
    }
  ],
  "type": "OwnedChannel"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChannelCallbackGraphiqlQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChannelCallbackGraphiqlQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ChannelCallbackGraphiqlQuery",
    "operationKind": "query",
    "text": "query ChannelCallbackGraphiqlQuery(\n  $channel_id: ID!\n) {\n  viewer {\n    id\n  }\n  node(id: $channel_id) {\n    __typename\n    id\n    ... on OwnedChannel {\n      id\n      callbackQuery\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bce1067ad8d0a936955b1a781ac64172';

module.exports = node;
