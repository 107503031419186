/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation SendMutation($input: SendInput!) {
    send(input: $input) {
      messageEdge {
        node {
          ...Message_message
        }
      }
    }
  }
`;

const commit = function(id, msg, viewer, onSuccess) {
  const variables = {
    input: msg,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: onSuccess,
  });
};

export default {commit};
