/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Messages_channel$ref = any;
export type MessagesPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  channelID: string,
|};
export type MessagesPaginationQueryResponse = {|
  +channel: ?{|
    +$fragmentRefs: Messages_channel$ref
  |}
|};
export type MessagesPaginationQuery = {|
  variables: MessagesPaginationQueryVariables,
  response: MessagesPaginationQueryResponse,
|};
*/


/*
query MessagesPaginationQuery(
  $count: Int!
  $cursor: String
  $channelID: ID!
) {
  channel: node(id: $channelID) {
    __typename
    ...Messages_channel_1G22uz
    id
  }
}

fragment Message_message on Message {
  id
  title
  body
  createdAt
  ...Replies_message
  channel {
    id
    name
  }
  receiver {
    id
    fullName
  }
  actions {
    id
    value
    kind
  }
}

fragment Messages_channel_1G22uz on Channel {
  id
  joined {
    id
    messages(first: $count, after: $cursor) {
      edges {
        node {
          id
          ...Message_message
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment Replies_message on Message {
  id
  replies(last: 10) {
    edges {
      node {
        id
        ...Reply_reply
        __typename
      }
      cursor
    }
    pageInfo {
      hasPreviousPage
      startCursor
    }
  }
}

fragment Reply_reply on Reply {
  action {
    id
    value
    kind
  }
  createdAt
  text
  id
  user {
    id
    fullName
    email
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "channelID",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "channelID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "last",
    "value": 10
  }
],
v7 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessagesPaginationQuery",
    "selections": [
      {
        "alias": "channel",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "Messages_channel"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessagesPaginationQuery",
    "selections": [
      {
        "alias": "channel",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JoinedChannel",
                "kind": "LinkedField",
                "name": "joined",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "MessageConnection",
                    "kind": "LinkedField",
                    "name": "messages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Message",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "body",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": (v6/*: any*/),
                                "concreteType": "ReplyConnection",
                                "kind": "LinkedField",
                                "name": "replies",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReplyEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Reply",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Action",
                                            "kind": "LinkedField",
                                            "name": "action",
                                            "plural": false,
                                            "selections": (v7/*: any*/),
                                            "storageKey": null
                                          },
                                          (v5/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "text",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ChannelUser",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v8/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "email",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v9/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasPreviousPage",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "startCursor",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "replies(last:10)"
                              },
                              {
                                "alias": null,
                                "args": (v6/*: any*/),
                                "filters": null,
                                "handle": "connection",
                                "key": "Replies_replies",
                                "kind": "LinkedHandle",
                                "name": "replies"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JoinedChannel",
                                "kind": "LinkedField",
                                "name": "channel",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChannelUser",
                                "kind": "LinkedField",
                                "name": "receiver",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Action",
                                "kind": "LinkedField",
                                "name": "actions",
                                "plural": true,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "Messages_messages",
                    "kind": "LinkedHandle",
                    "name": "messages"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Channel"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MessagesPaginationQuery",
    "operationKind": "query",
    "text": "query MessagesPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $channelID: ID!\n) {\n  channel: node(id: $channelID) {\n    __typename\n    ...Messages_channel_1G22uz\n    id\n  }\n}\n\nfragment Message_message on Message {\n  id\n  title\n  body\n  createdAt\n  ...Replies_message\n  channel {\n    id\n    name\n  }\n  receiver {\n    id\n    fullName\n  }\n  actions {\n    id\n    value\n    kind\n  }\n}\n\nfragment Messages_channel_1G22uz on Channel {\n  id\n  joined {\n    id\n    messages(first: $count, after: $cursor) {\n      edges {\n        node {\n          id\n          ...Message_message\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment Replies_message on Message {\n  id\n  replies(last: 10) {\n    edges {\n      node {\n        id\n        ...Reply_reply\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment Reply_reply on Reply {\n  action {\n    id\n    value\n    kind\n  }\n  createdAt\n  text\n  id\n  user {\n    id\n    fullName\n    email\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b47410f1cbecf91cc4d18d0fe24747bc';

module.exports = node;
