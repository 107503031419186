import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation CreateTeamInviteMutation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitationEdge {
        node {
          id
          kind
          email
          url
        }
      }
    }
  }
`;

const sharedUpdater = (store, newEdge) => {
  const teamProxy = store
    .getRoot()
    .getLinkedRecord('viewer')
    .getLinkedRecord('team');
  const conn = ConnectionHandler.getConnection(teamProxy, 'Team_invitations');
  if (conn) {
    return ConnectionHandler.insertEdgeAfter(conn, newEdge);
  }
};

let tempID = 0;

const commit = function(email, onSuccess, onError) {
  let kind = 'URL';
  const variables = {
    input: {
      email,
      kind,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (store) => {
      const payload = store.getRootField('createInvitation');
      const node = payload
        .getLinkedRecord('invitationEdge')
        .getLinkedRecord('node');
      const teamProxy = store
        .getRoot()
        .getLinkedRecord('viewer')
        .getLinkedRecord('team');
      console.log('teamProxy', teamProxy);
      const conn = ConnectionHandler.getConnection(
        teamProxy,
        'Team_invitations',
      );
      console.log('conn', conn);
      const newEdge = ConnectionHandler.createEdge(
        store,
        conn,
        node,
        'InvitationEdge',
      );
      return sharedUpdater(store, newEdge);
    },
    optimisticUpdater: (store) => {
      const id = 'client:newInvitation:' + tempID++;
      const node = store.create(id, 'Invitation');
      node.setValue(kind, 'kind');
      node.setValue(id, 'id');
      node.setValue(email, 'email');
      node.setValue('Creating...', 'url');
      const newEdge = store.create(
        'client:newEdge:' + tempID++,
        'InvitationEdge',
      );
      newEdge.setLinkedRecord(node, 'node');
      return sharedUpdater(store, newEdge);
    },
    onCompleted: onSuccess,
    onError,
  });
};

export default { commit };
