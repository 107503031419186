import {
  Environment,
  Network,
  RecordSource,
  QueryResponseCache,
  Store,
  Observable,
} from 'relay-runtime';
import { default as TheStore } from './components/Store';
import { WEBHOST } from './const';

//import ActionCable from 'actioncable';
import { createConsumer } from './action_cable';

let cable = createConsumer(() => {
  return `${WEBHOST}/cable/?authorization=${TheStore.getCachedToken()}`;
});

//import {auth} from "./components/Auth"
const cache = new QueryResponseCache({ size: 100, ttl: 100000 });
TheStore.setCacheClear(() => {
  cache.clear();
});

let subscriptionHandler = (operation, variables, cacheConfig) => {
  //let token = await TheStore.getToken();
  return Observable.create(({ next, error, complete }) => {
    // unique-ish
    var channelId = Math.round(Date.now() + Math.random() * 100000).toString(
      16,
    );
    // Register the subscription by subscribing to the channel
    const subscription = cable.subscriptions.create(
      {
        channel: 'GraphqlChannel',
        channelId: channelId,
      },
      {
        connected: function() {
          var channelParams = {
            variables: variables,
            operationName: operation.name,
            query: operation.text,
          };

          this.perform('execute', channelParams);
        },
        // This result is sent back from ActionCable.
        received: function(payload, more) {
          // When we get a response, send the update to `observer`
          const result = payload.result;
          if (result && result.errors) {
            // What kind of error stuff belongs here?
            error(result.errors);
          } else if (result) {
            next({ data: result.data });
          }
          if (!payload.more) {
            // Subscription is finished
            complete();
          }
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  });
};
export { subscriptionHandler };

async function fetchQuery(operation, variables, cacheConfig) {
  const queryId = operation.name;
  const cachedData = cache.get(queryId, variables);

  // Handle force option in RefetchOptions
  // See: https://facebook.github.io/relay/docs/pagination-container.html
  // https://facebook.github.io/relay/docs/refetch-container.html
  const forceLoad = cacheConfig && cacheConfig.force;
  if (!forceLoad && cachedData) {
    return cachedData;
  }
  if (forceLoad) {
    // clear() means to reset all the cache, not only the entry addressed by specific queryId.
    // See blog comments for more details.
    cache.clear();
  }

  let headers = {
    'Content-Type': 'application/json',
  };

  let token = await TheStore.getToken();
  if (token !== null) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  console.log('fetch', operation.name);
  return fetch(`${WEBHOST}/graphql`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
      operationName: operation.name,
    }),
  })
    .then((response) => {
      if (response.status === 401) {
        TheStore.logout();
      }
      return response.json();
    })
    .then((json) => {
      if (json.hasOwnProperty('errors')) {
        const error = createRequestError('200', json);
        TheStore.onFailure(error.message);
        throw error.error;
      } else TheStore.onFailure(null);
      cache.set(queryId, variables, json);
      return json;
    });
}

const modernEnvironment = new Environment({
  network: Network.create(fetchQuery, subscriptionHandler),
  store: new Store(new RecordSource()),
});

function formatRequestErrors(errors) {
  return errors
    .map(({ locations, message }, ii) => {
      const prefix = ii + 1 + '. ';
      return prefix + message;
    })
    .join('\n');
}

export function createRequestError(responseStatus, payload) {
  const errorReason =
    typeof payload === 'object'
      ? formatRequestErrors(payload.errors)
      : `Server response had an error status: ${responseStatus}`;
  let trace = '';
  if (payload.hasOwnProperty('trace')) {
    payload.trace.map((info) => {
      return (trace += info + '\n');
    });
  }

  const error = new Error(
    `Server request ` +
      `failed for the following reasons:\n\n${errorReason}\n\n${trace}`,
  );
  error.source = payload;
  error.status = responseStatus;
  return {
    error,
    message: errorReason,
  };
}

export default modernEnvironment;
