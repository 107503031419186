import React from 'react';

class QueryError extends React.Component {
  render() {
    return (
      <div>
        {this.props.error.message.split('\n').map((info, i) => {
          return <p key={i}>{info}</p>;
        })}
        <p>
          <button onClick={this.props.retry}>Retry?</button>
        </p>
      </div>
    );
  }
}

export default QueryError;
