/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';
import { ConnectionHandler } from 'relay-runtime';

const mutation = graphql`
  mutation DeleteChannelMutation($input: DeleteChannelInput!) {
    deleteChannel(input: $input) {
      deletedId
      deletedJoinedChannelId
    }
  }
`;

const sharedUpdater = (viewer, viewerID, deletedID, deletedJoinedChannelID) => {
  const viewerProxy = viewer.get(viewerID);
  const conn = ConnectionHandler.getConnection(
    viewerProxy,
    'Channels_channels',
  );
  ConnectionHandler.deleteNode(conn, deletedID);

  const conn2 = ConnectionHandler.getConnection(
    viewerProxy,
    'Channels_joinedChannels',
    [],
  );
  if (conn2 != null) {
    return ConnectionHandler.deleteNode(conn2, deletedJoinedChannelID);
  }
};

const commit = function(channelID, viewerID, onSuccess) {
  const variables = {
    input: {
      channelId: channelID,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (viewer) => {
      const payload = viewer.getRootField('deleteChannel');
      return sharedUpdater(
        viewer,
        viewerID,
        payload.getValue('deletedId'),
        payload.getValue('deletedJoinedChannelId'),
      );
    },
    onCompleted: onSuccess,
  });
};

export default { commit };
