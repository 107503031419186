/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';

import Step1 from './Step1';
import Step3 from './Step3';
import Step4 from './Step4';
import Card from '../Card';
import Store from '../Store';
import useCurrentUser from '../useCurrentUser';
import VerifyAccount from '../VerifyAccount';

let DeviceSetup = ({ viewer }) => {
  const [currentUser] = useCurrentUser();
  let [activeStep, setActiveStep] = React.useState(0);
  let [response, setResponse] = React.useState(null);
  let [responseLoading, setResponseLoading] = React.useState(false);

  React.useEffect(() => {
    const show2 = viewer?.devices?.edges?.length > 0;
    if (show2) {
      setActiveStep(1);
      return;
    }
  }, [viewer]);

  let onStep = (step) => {
    setActiveStep(step);
  };

  let onResponse = (resp, err) => {
    console.log('resp', resp, err);
    setResponseLoading(false);
    if (err) {
      setActiveStep(1);
      Store.onFailure(err);
      return;
    }
    console.log('setREsponse');
    setResponse(resp);
  };

  let onSend = () => {
    setResponseLoading(true);
    setResponse(null);
    setActiveStep(2);
  };

  return (
    <Card className="device-setup-card">
      <h2>Setup your device</h2>
      <p>
        Follow the steps below to start receiving notifications on your Android
        or iOS device
      </p>
      <Stepper activeStep={activeStep} orientation="vertical">
        {currentUser.isAnonymous && (
          <Step>
            <StepLabel>Choose an account provider</StepLabel>
            <StepContent>
              <VerifyAccount />
            </StepContent>
          </Step>
        )}
        <Step>
          <StepLabel>Install and login on mobile app</StepLabel>
          <StepContent>
            <Step1 onStep={onStep} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Send interactive message</StepLabel>
          <StepContent
            TransitionProps={{
              unmountOnExit: false,
            }}
          >
            <Step3 viewer={viewer} onSend={onSend} onResponse={onResponse} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Select action</StepLabel>
          <StepContent>
            <Step4 response={response} responseLoading={responseLoading} />
          </StepContent>
        </Step>
      </Stepper>
    </Card>
  );
};

DeviceSetup = createFragmentContainer(DeviceSetup, {
  viewer: graphql`
    fragment DeviceSetup_viewer on User {
      id
      email
      accessToken {
        secret
      }
      devices(first: 100) @connection(key: "Account_devices") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});

export default DeviceSetup;
