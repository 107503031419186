/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import RemoveIcon from '@material-ui/icons/Remove';
import DeleteChannelUserMutation from '../mutations/DeleteChannelUserMutation';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
    this.state = { foo: 'bar' };
  }

  onRemove(id) {
    return (e) => {
      return DeleteChannelUserMutation.commit(
        this.props.viewer.id,
        id,
        this.props.channel.id,
      );
    };
  }

  users() {
    const { classes } = this.props;
    return this.props.channel.users.edges.map((edge) => {
      if (edge.node != null) {
        return (
          <div key={edge.node.id} className={classes.row}>
            <IconButton id={edge.node.id} onClick={this.onRemove(edge.node.id)}>
              <RemoveIcon />
            </IconButton>
            <div>{edge.node.fullName}&nbsp; &nbsp;</div>
            <div> {edge.node.email || ''} </div>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    return <div>{this.users()}</div>;
  }
}

Users = createFragmentContainer(Users, {
  channel: graphql`
    fragment Users_channel on OwnedChannel {
      id
      users(first: 100) @connection(key: "Users_users") {
        edges {
          node {
            id
            fullName
            email
          }
        }
      }
    }
  `,
});

export default withStyles(styles, { withTheme: true })(Users);
