/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Subscribe_viewer$ref: FragmentReference;
declare export opaque type Subscribe_viewer$fragmentType: Subscribe_viewer$ref;
export type Subscribe_viewer = {|
  +id: string,
  +email: ?string,
  +subscribed: string,
  +messageCount: number,
  +$refType: Subscribe_viewer$ref,
|};
export type Subscribe_viewer$data = Subscribe_viewer;
export type Subscribe_viewer$key = {
  +$data?: Subscribe_viewer$data,
  +$fragmentRefs: Subscribe_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subscribe_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscribed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageCount",
      "storageKey": null
    }
  ],
  "type": "User"
};
// prettier-ignore
(node/*: any*/).hash = '6afa78b6504b7d1b30e242d32b7c62d0';

module.exports = node;
