/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation CreateInvitationMutation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitationEdge {
        node {
          id
          kind
          url
          token
        }
      }
    }
  }
`;

const sharedUpdater = (viewer, channelID, newEdge) => {
  const channelProxy = viewer.get(channelID);
  const conn = ConnectionHandler.getConnection(
    channelProxy,
    'Invitations_invitations',
  );
  if (conn) {
    return ConnectionHandler.insertEdgeAfter(conn, newEdge);
  }
};

let tempID = 0;

const commit = function(channelId, kind, onSuccess) {
  const variables = {
    input: {
      channelId,
      kind,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (viewer) => {
      const payload = viewer.getRootField('createInvitation');
      const node = payload
        .getLinkedRecord('invitationEdge')
        .getLinkedRecord('node');
      const channelProxy = viewer.get(channelId);
      const conn = ConnectionHandler.getConnection(
        channelProxy,
        'Invitations_invitations',
      );
      const newEdge = ConnectionHandler.createEdge(
        viewer,
        conn,
        node,
        'InvitationEdge',
      );
      return sharedUpdater(viewer, channelId, newEdge);
    },
    optimisticUpdater: (viewer) => {
      const id = 'client:newInvitation:' + tempID++;
      const node = viewer.create(id, 'Invitation');
      node.setValue(kind, 'kind');
      node.setValue(id, 'id');
      node.setValue('Creating...', 'url');
      const newEdge = viewer.create(
        'client:newEdge:' + tempID++,
        'InvitationEdge',
      );
      newEdge.setLinkedRecord(node, 'node');
      return sharedUpdater(viewer, channelId, newEdge);
    },
    onCompleted: onSuccess,
  });
};

export default { commit };
