/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Reply_reply$ref = any;
export type ReplySentSubscriptionVariables = {||};
export type ReplySentSubscriptionResponse = {|
  +replySent: {|
    +node: ?{|
      +id: string,
      +message: {|
        +id: string
      |},
      +$fragmentRefs: Reply_reply$ref,
    |}
  |}
|};
export type ReplySentSubscription = {|
  variables: ReplySentSubscriptionVariables,
  response: ReplySentSubscriptionResponse,
|};
*/


/*
subscription ReplySentSubscription {
  replySent {
    node {
      id
      ...Reply_reply
      message {
        id
      }
    }
  }
}

fragment Reply_reply on Reply {
  action {
    id
    value
    kind
  }
  createdAt
  text
  id
  user {
    id
    fullName
    email
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Message",
  "kind": "LinkedField",
  "name": "message",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReplySentSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReplyEdge",
        "kind": "LinkedField",
        "name": "replySent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reply",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Reply_reply"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReplySentSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReplyEdge",
        "kind": "LinkedField",
        "name": "replySent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reply",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Action",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChannelUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ReplySentSubscription",
    "operationKind": "subscription",
    "text": "subscription ReplySentSubscription {\n  replySent {\n    node {\n      id\n      ...Reply_reply\n      message {\n        id\n      }\n    }\n  }\n}\n\nfragment Reply_reply on Reply {\n  action {\n    id\n    value\n    kind\n  }\n  createdAt\n  text\n  id\n  user {\n    id\n    fullName\n    email\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a09a995d5fd0615b254e48968af8901';

module.exports = node;
