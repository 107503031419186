/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';

import { formatReplyDate } from '../formatDate';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  date: {
    fontSize: 10,
    marginLeft: 10,
    color: '#999',
  },
});

class Reply extends React.Component {
  userName(user) {
    if (user.fullName) {
      return user.fullName;
    } else if (user.email != null) {
      return user.email;
    } else {
      return user.id;
    }
  }

  render() {
    const { classes } = this.props;
    let text = this.props.reply.action.value;
    if (this.props.reply.action.kind === 'text') {
      ({ text } = this.props.reply);
    }
    return (
      <div>
        {this.userName(this.props.reply.user)}: {text}
        <span className={classes.date}>
          {formatReplyDate(this.props.reply.createdAt)}
        </span>
      </div>
    );
  }
}

Reply = createFragmentContainer(Reply, {
  reply: graphql`
    fragment Reply_reply on Reply {
      action {
        id
        value
        kind
      }
      createdAt
      text
      id
      user {
        id
        fullName
        email
      }
    }
  `,
});

export default withStyles(styles)(Reply);
