/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Reply_reply$ref: FragmentReference;
declare export opaque type Reply_reply$fragmentType: Reply_reply$ref;
export type Reply_reply = {|
  +action: {|
    +id: string,
    +value: string,
    +kind: string,
  |},
  +createdAt: any,
  +text: ?string,
  +id: string,
  +user: {|
    +id: string,
    +fullName: ?string,
    +email: ?string,
  |},
  +$refType: Reply_reply$ref,
|};
export type Reply_reply$data = Reply_reply;
export type Reply_reply$key = {
  +$data?: Reply_reply$data,
  +$fragmentRefs: Reply_reply$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Reply_reply",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Action",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChannelUser",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Reply"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7823f92ec732903e085d8a38cca42ce';

module.exports = node;
