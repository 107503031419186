import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCy9cqy9ueNCGAPbwPB_C4bQZUEQXZzD8I',
  authDomain: 'plainice-284f2.firebaseapp.com',
  databaseURL: 'https://plainice-284f2.firebaseio.com',
  projectId: 'plainice-284f2',
  storageBucket: 'plainice-284f2.appspot.com',
  messagingSenderId: '681701113678',
  appId: '1:681701113678:web:c40a438fdcdc746823a81f',
};
let firebaseApp = firebase.initializeApp(firebaseConfig);
//firebaseApp.auth().useEmulator('http://localhost:9099/');

export default firebaseApp;
