/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    backgroundColor: 'white',
    padding: 15,
    lineHeight: 1.5,
    minHeight: 50,
  },
});

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = { foo: 'bar' };
  }

  render() {
    const { classes, theme, ...rest } = this.props;
    return (
      <div {...rest} className={classnames(classes.root, this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Card);
