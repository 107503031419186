/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';
import { ConnectionHandler } from 'relay-runtime';

const mutation = graphql`
  mutation DeleteChannelUserMutation($input: DeleteChannelUserInput!) {
    deleteChannelUser(input: $input) {
      deletedId
      deletedJoinedChannelId
    }
  }
`;

const commit = function(viewerID, userID, channelID, onSuccess) {
  const variables = {
    input: {
      userId: userID,
      channelId: channelID,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (viewer) => {
      const payload = viewer.getRootField('deleteChannelUser');
      const userId = payload.getValue('deletedId');
      const chanId = payload.getValue('deletedJoinedChannelId');
      console.log('userID', userID, channelID);
      const channelProxy = viewer.get(channelID);
      let isOwned = false;
      let conn = ConnectionHandler.getConnection(
        channelProxy,
        'Users_users',
        [],
      );
      if (conn != null) {
        ConnectionHandler.deleteNode(conn, userId);
        isOwned = true;
      }
      const owned = channelProxy.getLinkedRecord('owned');
      if (owned) {
        conn = ConnectionHandler.getConnection(owned, 'Users_users', []);
        if (conn != null) {
          ConnectionHandler.deleteNode(conn, userId);
          isOwned = true;
        }
      }

      const viewerProxy = viewer.get(viewerID);
      if (isOwned && viewerID === userID) {
        viewer.delete(chanId);
      }
      console.log('isOwned', isOwned);
      if (!isOwned) {
        conn = ConnectionHandler.getConnection(
          viewerProxy,
          'Channels_channels',
          [],
        );
        console.log('conn', conn, channelID);
        if (conn != null) {
          return ConnectionHandler.deleteNode(conn, channelID);
        }
      }
    },
    onCompleted: onSuccess,
  });
};

export default { commit };
