/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation DeleteInvitationMutation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      deletedId
    }
  }
`;

const sharedUpdater = (viewer, channelID, deletedID) => {
  const channelProxy = viewer.get(channelID);
  const conn = ConnectionHandler.getConnection(
    channelProxy,
    'Invitations_invitations',
  );
  if (conn) {
    return ConnectionHandler.deleteNode(conn, deletedID);
  }
};

const commit = function(id, channelID, onSuccess) {
  const variables = {
    input: {
      id,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (viewer) => {
      const payload = viewer.getRootField('deleteInvitation');
      return sharedUpdater(viewer, channelID, payload.getValue('deletedId'));
    },
  });
};

export default { commit };
