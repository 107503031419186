export default function defaultMessage() {
  return {
    title: 'Send notifications',
    body: 'Get responses back',
    action0: {
      enabled: true,
      type: 'button',
      text: 'Action1',
    },
    action1: {
      enabled: false,
      type: 'button',
      text: 'Action2',
    },
    action2: {
      enabled: true,
      type: 'text',
      text: 'Reply',
    },
  };
}
