import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NavBar from './NavBar';
import Store from './Store';
import Card from './Card';
import firebaseApp from '../firebaseApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    minWidth: 300,
    padding: theme.spacing(2),
    flexDirection: 'column',
  },
  send: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-end',
  },
}));

export default function LoginWithEmail({ drawerToggle }) {
  let classes = useStyles();
  let [email, setEmail] = React.useState('');
  let [loading, setLoading] = React.useState(false);

  let login = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem('signInEmail', email);
    try {
      await firebaseApp.auth().sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url: 'https://app.pushback.io/',
        android: {
          installApp: true,
          packageName: 'io.pushback',
        },
        iOS: {
          bundleId: 'io.pushback.PushBack',
        },
      });
      setLoading(false);
      Store.setSuccess('Login link sent. Please check your inbox');
    } catch (e) {
      Store.onFailure(e);
    }
  };

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.toolbar} />
      <Card className={classes.card}>
        <form className={classes.form} name="login" onSubmit={login}>
          <Typography className={classes.title}>
            Please enter an email to login or sign up
          </Typography>
          <TextField
            fullWidth
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            label="email"
            autoComplete="email"
            type="email"
            required
            style={{ width: '240px' }}
          />
          <Button
            className={classes.send}
            color="primary"
            type="submit"
            disabled={loading}
          >
            Send
          </Button>
        </form>
      </Card>
    </div>
  );
}
