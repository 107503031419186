/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/lightBlue';
import { CurrentUserProvider } from './components/useCurrentUser';

import Store from './components/Store';
import RelayEnv from './RelayEnv';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/index.css';

window.Store = Store;
window.RelayEnv = RelayEnv;

const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 500,
      marginTop: 16,
      marginBottom: 16,
    },
    h2: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 26,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 20,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      marginTop: 8,
      marginBottom: 8,
    },
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  },
  palette: {
    primary: red,
    secondary: indigo,
    background: {
      default: '#eee',
      dark: '#b0bec5',
    },
  },
});

document.addEventListener('DOMContentLoaded', () => {
  console.log('HERE I AM');
  return ReactDOM.render(
    <ErrorBoundary>
      <CurrentUserProvider>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </CurrentUserProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
});
