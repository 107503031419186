/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {requestSubscription} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {ConnectionHandler} from 'relay-runtime';

import environment from '../RelayEnv';

const subscription = graphql`
  subscription MessageSentSubscription {
    messageSent {
      node {
        id
        ...Message_message
        channel {
          id
        }
      }
    }
  }
`;

const subscribe = function(channelID, viewerID, options) {
  if (options == null) {
    options = {};
  }
  return requestSubscription(environment, {
    subscription,
    variables: {},
    updater: store => {
      let channelProxy, conn, newEdge;
      const payload = store.getRootField('messageSent');
      console.log('payload', payload);
      const node = payload.getLinkedRecord('node');
      const channel = node.getLinkedRecord('channel');
      if (channel != null) {
        channelProxy = store.get(channel.getValue('id'));
        conn = ConnectionHandler.getConnection(
          channelProxy,
          'Messages_messages',
        );
        if (conn != null) {
          newEdge = ConnectionHandler.createEdge(
            store,
            conn,
            node,
            'MessageEgde',
          );
          ConnectionHandler.insertEdgeBefore(conn, newEdge);
        }
      }

      channelProxy = store.get(viewerID);
      conn = ConnectionHandler.getConnection(
        channelProxy,
        'UserMessages_messages',
      );
      newEdge = ConnectionHandler.createEdge(store, conn, node, 'MessageEgde');
      return ConnectionHandler.insertEdgeBefore(conn, newEdge);
    },
    onNext: resp => {},
    ...options,
  });
};

export default {subscribe};
