/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Channel_channel$ref = any;
type Invitations_channel$ref = any;
type Messages_channel$ref = any;
type Users_channel$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChannelView_channel$ref: FragmentReference;
declare export opaque type ChannelView_channel$fragmentType: ChannelView_channel$ref;
export type ChannelView_channel = {|
  +id: string,
  +name: string,
  +owned: ?{|
    +id: string,
    +name: string,
    +$fragmentRefs: Channel_channel$ref & Invitations_channel$ref & Users_channel$ref,
  |},
  +joined: ?{|
    +id: string,
    +name: string,
    +channelId: string,
  |},
  +$fragmentRefs: Messages_channel$ref,
  +$refType: ChannelView_channel$ref,
|};
export type ChannelView_channel$data = ChannelView_channel;
export type ChannelView_channel$key = {
  +$data?: ChannelView_channel$data,
  +$fragmentRefs: ChannelView_channel$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelView_channel",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OwnedChannel",
      "kind": "LinkedField",
      "name": "owned",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Channel_channel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Invitations_channel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Users_channel"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JoinedChannel",
      "kind": "LinkedField",
      "name": "joined",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "channelId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Messages_channel"
    }
  ],
  "type": "Channel"
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c784bbc1bcd6503bd50295f8e816c9e';

module.exports = node;
