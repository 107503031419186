/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import {parse} from 'graphql/language';
import {print as onelinePrinter} from '../graphql_printer';
import {withStyles} from '@material-ui/core/styles';
import APIHOST from '../const';

const styles = theme => ({
  root: {
    margin: '10px 10px',
    backgroundColor: 'white',
    padding: 6,
    [theme.breakpoints.up('md')]: {
      padding: 3,
    },
  },

  content: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    content: '$ ',
  },
});

const Curl = ({accessToken, query, variables, close, classes}) => {
  let q = '';
  let v = '';
  try {
    q = onelinePrinter(parse(query));
    v = JSON.stringify(JSON.parse(variables));
  } catch (e) {
    console.log(e);
  }

  return (
    <div className={classes.root}>
      <code>
        <div className="curl-hide" onClick={close}>
          {'\u2715'}
        </div>
        <div className="method-example-request include-prompt">
          <div className={classes.content}>
            curl -s {APIHOST}/graphql \{'\n  '}
            <span className="token api-key">
              <span className="token request-flag">-u</span> {accessToken}
            </span>
            : \{'\n  '}
            <span className="token">
              <span className="token request-flag">-d</span> {`'query=${q}'`}
            </span>{' '}
            \{'\n  '}
            <span className="token">
              <span className="token request-flag">-d</span>{' '}
              {`'variables=${v}'`}
            </span>
          </div>
        </div>
      </code>
    </div>
  );
};

export default withStyles(styles)(Curl);
