/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { createFragmentContainer, QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Redirect } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import QueryError from './QueryError';
import Card from './Card';
import Channel from './Channel';
import Invitations from './Invitations';
import Users from './Users';
import SimpleCurl from './SimpleCurl';
import Messages from './Messages';
import Loading from './Loading';
import RelayEnv from '../RelayEnv';
import DeleteChannelMutation from '../mutations/DeleteChannelMutation';
import DeleteChannelUserMutation from '../mutations/DeleteChannelUserMutation';

const styles = (theme) => ({
  title: {
    margin: '0 0 16px',
  },

  tabs: {
    backgroundColor: 'white',
  },
});

class ChannelGrid extends React.Component {
  constructor(props) {
    super(props);
    this.deleteChannel = this.deleteChannel.bind(this);
    this.leave = this.leave.bind(this);
    this.onDisableNotifs = this.onDisableNotifs.bind(this);
    this.state = {
      foo: 'bar',
      activeTab: 0,
      channelId: null,
    };
  }

  deleteChannel(e) {
    if (window.confirm('Are you sure?')) {
      return DeleteChannelMutation.commit(
        this.props.channel.owned.id,
        this.props.viewer.id,
        () => {
          return this.setState({
            redirect: true,
          });
        },
      );
    }
  }

  leave() {
    return DeleteChannelUserMutation.commit(
      this.props.viewer.id,
      this.props.viewer.id,
      this.props.channel.id,
      () => {
        if (this.props.channel.owned == null) {
          return this.setState({
            redirect: true,
          });
        }
      },
    );
  }

  onDisableNotifs() {}

  render() {
    const { classes } = this.props;
    if (this.props.channel == null || this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            onChange={(e, tabId) => this.setState({ activeTab: tabId })}
          >
            <Tab value={0} label="Messages" />
            <Tab value={1} label="Channel" />
          </Tabs>
        </Grid>
        {(() => {
          if (this.state.activeTab === 0) {
            return (
              <React.Fragment>
                {this.props.channel.owned != null &&
                this.props.channel.joined != null ? (
                  <Grid item xs={12}>
                    <Card>
                      <SimpleCurl
                        accessToken={this.props.viewer.accessToken.secret}
                        id={this.props.channel.id}
                      />
                    </Card>
                  </Grid>
                ) : (
                  undefined
                )}
                <Grid item xs={12}>
                  <Messages
                    viewer={this.props.viewer}
                    channel={this.props.channel}
                  />
                </Grid>
              </React.Fragment>
            );
          } else if (this.state.activeTab === 1) {
            return (
              <React.Fragment>
                {this.props.channel.owned != null ? (
                  <Grid item xs={12} md={6}>
                    <Channel
                      viewer={this.props.viewer}
                      channelId={this.props.channel.id}
                      channel={this.props.channel.owned}
                    />
                  </Grid>
                ) : (
                  undefined
                )}
                <Grid item xs={12} md={6}>
                  <Card>
                    {this.props.channel.joined != null ? (
                      <div>
                        <Typography variant="body1">
                          Leave Channel to no longer receive messages
                        </Typography>
                        <Button color="primary" onClick={this.leave}>
                          Leave
                        </Button>
                      </div>
                    ) : (
                      undefined
                    )}
                    <br />
                    {this.props.channel.owned != null ? (
                      <div>
                        <Typography variant="body1">Delete Channel</Typography>
                        <Button onClick={this.deleteChannel} color="primary">
                          Delete Channel
                        </Button>
                      </div>
                    ) : (
                      undefined
                    )}
                  </Card>
                </Grid>
                {this.props.channel.owned != null ? (
                  <React.Fragment>
                    <Grid item xs={12} md={6}>
                      <Invitations channel={this.props.channel.owned} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card>
                        <Typography variant="h2" className={classes.title}>
                          Users
                        </Typography>
                        <Users
                          channel={this.props.channel.owned}
                          viewer={this.props.viewer}
                        />
                      </Card>
                    </Grid>
                  </React.Fragment>
                ) : (
                  undefined
                )}
              </React.Fragment>
            );
          }
        })()}
      </Grid>
    );
  }
}

ChannelGrid = createFragmentContainer(ChannelGrid, {
  channel: graphql`
    fragment ChannelView_channel on Channel {
      id
      name
      ...Messages_channel
      owned {
        id
        name
        ...Channel_channel
        ...Invitations_channel
        ...Users_channel
      }
      joined {
        id
        name
        channelId
      }
    }
  `,
});
ChannelGrid = withStyles(styles, { withTheme: true })(ChannelGrid);

class ChannelView extends React.Component {
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query ChannelViewQuery($channel_id: ID!) {
            viewer {
              id
              accessToken {
                secret
              }
            }
            node(id: $channel_id) {
              ... on Channel {
                id
                ...ChannelView_channel
              }
            }
          }
        `}
        environment={RelayEnv}
        variables={{ channel_id: this.props.match.params.channel_id }}
        render={({ error, retry, props }) => {
          if (error) {
            return <QueryError retry={retry} error={error} />;
          } else if (props) {
            return (
              <ChannelGrid
                viewer={props.viewer}
                channel={props.node}
                {...props}
              />
            );
          } else {
            return <Loading />;
          }
        }}
      />
    );
  }
}

export default ChannelView;
