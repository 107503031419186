/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import GraphiQL from 'graphiql';
import 'graphiql/graphiql.min.css';
import { parse, print } from 'graphql/language';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';

import NavBar from './NavBar';
import { WEBHOST } from '../const';
import Curl from './Curl';
import Store from './Store';

import { subscriptionHandler } from '../RelayEnv';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  toolbar: theme.mixins.toolbar,
});

const hasSubscriptionOperation = (graphQlParams) => {
  const queryDoc = parse(graphQlParams.query);

  for (let definition of Array.from(queryDoc.definitions)) {
    if (definition.kind === 'OperationDefinition') {
      const { operation } = definition;
      if (operation === 'subscription') {
        return true;
      }
    }
  }
  return false;
};

//cable = ActionCable.createConsumer("ws://localhost:3000/cable?authorization=#{Store.getToken()}")
//subscriptionHandler = createActionCableHandler cable

const graphQLFetcher = async (graphQLParams) => {
  if ((graphQLParams != null ? graphQLParams.query : undefined) == null) {
    return;
  }

  if (hasSubscriptionOperation(graphQLParams)) {
    return {
      subscribe: (observer) => {
        return subscriptionHandler(
          {
            text: graphQLParams.query,
            variables: graphQLParams.variables,
          },
          {},
          {},
          observer,
        );
      },
      unsubscribe: (observer) => {},
    };
  }
  //observer.next('Your subscription data will appear here after server publication!');

  //activeSubscriptionId = subscriptionsClient.subscribe
  //  query: graphQLParams.query
  //  variables: graphQLParams.variables
  //, (error, result) =>
  //  if error
  //    observer.error error
  //  else
  //    observer.next result
  const headers = await Store.getHeaders();
  headers['Content-Type'] = 'application/json';

  return fetch(`${WEBHOST}/graphql`, {
    method: 'post',
    headers,
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
};

class GraphiqlContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formattedQuery = this.formattedQuery.bind(this);
    this.variables = this.variables.bind(this);
    this.toggleCurl = this.toggleCurl.bind(this);
    this.history = this.history.bind(this);
    this.prettify = this.prettify.bind(this);
    this.queryEdit = this.queryEdit.bind(this);
    this.varsEdit = this.varsEdit.bind(this);
    this.closeCurl = this.closeCurl.bind(this);
    const search = new URLSearchParams(document.location.search);
    const channelId = search.get('channel_id');
    this.state = {
      channelId,
      showCurl: true,
      query: this.formattedQuery(),
      variables: this.variables(props.viewer),
    };
  }

  formattedQuery() {
    let query =
      'mutation SendSync($input: SendSyncInput!) { sendSync(input:$input) { responses(first: 1) { edges { node { action } } } } }';
    query =
      'mutation Send($input: SendInput!) { send(input:$input) { clientMutationId } }';
    //query = "subscription { actionReceived { message { body } } } "
    return print(parse(query));
  }

  variables(viewer) {
    const search = new URLSearchParams(document.location.search);
    let channelId = search.get('channel_id');
    if (!channelId) {
      channelId = viewer.id;
    }

    let varStr = '{}';
    if (channelId) {
      const vars = {
        input: {
          title: 'Hello World',
          body: 'This is pushback!',
          id: channelId,
          action1: 'ONE',
          action2: 'TWO',
          reply: 'Reply',
        },
      };
      varStr = JSON.stringify(vars, null, 2);
    }

    return varStr;
  }

  toggleCurl(e) {
    return this.setState({
      showCurl: !this.state.showCurl,
    });
  }

  history(event) {
    return this.graphiql.handleToggleHistory();
  }

  prettify(event) {
    const editor = this.graphiql.getQueryEditor();
    return editor.setValue(print(parse(editor.getValue())));
  }

  queryEdit(q) {
    return this.setState({
      query: q,
    });
  }

  varsEdit(v) {
    try {
      return this.setState({
        variables: v,
        curlVariables: JSON.stringify(JSON.parse(v)),
      });
    } catch (e) {}
  }

  closeCurl() {
    return this.setState({
      showCurl: false,
    });
  }

  render() {
    const { classes } = this.props;
    let accessToken = 'at_token';

    if (this.props.viewer.accessToken != null) {
      accessToken = this.props.viewer.accessToken.secret;
    }

    return (
      <div className={classes.root}>
        <NavBar noDrawer />
        {this.state.showCurl ? (
          <Curl
            query={this.state.query}
            variables={this.state.variables}
            accessToken={accessToken}
            close={this.closeCurl}
          />
        ) : (
          undefined
        )}
        <GraphiQL
          ref={(c) => {
            return (this.graphiql = c);
          }}
          fetcher={graphQLFetcher}
          query={this.state.query}
          variables={this.state.variables}
          onEditQuery={this.queryEdit}
          onEditVariables={this.varsEdit}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={this.prettify}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button
              onClick={this.history}
              label="History"
              title="History"
            />
            <GraphiQL.Button
              onClick={this.toggleCurl}
              label="Curl"
              title="Curl"
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    );
  }
}

GraphiqlContainer = createFragmentContainer(GraphiqlContainer, {
  viewer: graphql`
    fragment Graphiql_viewer on User {
      id
      accessToken {
        secret
      }
    }
  `,
});

export default withStyles(styles, { withTheme: true })(GraphiqlContainer);
