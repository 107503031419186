/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import DeviceSetup from './device_setup/DeviceSetup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

let FinishSetup = ({ viewer }) => {
  let classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={2}>
      <DeviceSetup viewer={viewer} />
    </Grid>
  );
};

FinishSetup = createFragmentContainer(FinishSetup, {
  viewer: graphql`
    fragment FinishSetup_viewer on User {
      ...DeviceSetup_viewer
      email
      devices(first: 100) @connection(key: "Home_devices") {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});

export default FinishSetup;
