import React from 'react';
import { Route, Redirect } from 'react-router';
import useCurrentUser from './useCurrentUser';
import Loading from './Loading';
import firebaseApp from '../firebaseApp';
import firebase from 'firebase/app';
import Store from './Store';

function PrivateRoute({ children, location, ...rest }) {
  let [currentUser] = useCurrentUser();
  let [loading, setLoading] = React.useState(true);

  let loginLink = false;
  if (location.search.match(/\?link=.*/)) {
    loginLink = true;
  }

  React.useEffect(() => {
    if (!currentUser || !loading) return;

    async function setup() {
      if (currentUser.uid && !currentUser.isAnonymous) {
        setLoading(false);
        return;
      }
      let link = `${window.location.origin}${location.path}/${location.search}`;
      console.log(
        'app componentDidMount',
        firebaseApp.auth().isSignInWithEmailLink(link),
      );
      if (firebaseApp.auth().isSignInWithEmailLink(link)) {
        console.log('1');
        try {
          const email = localStorage.getItem(`signInEmail`);
          console.log('2');
          if (email !== null) {
            console.log('3');
            if (firebaseApp.auth().currentUser?.isAnonymous) {
              console.log('4');
              let cred = firebase.auth.EmailAuthProvider.credentialWithLink(
                email,
                link,
              );
              await firebaseApp.auth().currentUser.linkWithCredential(cred);
              localStorage.removeItem('signInEmail');
              setLoading(false);
              console.log('navigate to main');
            } else {
              console.log('5');
              await firebaseApp.auth().signInWithEmailLink(email, link);
              setLoading(false);
            }
          } else {
            // TODO prompt for email
            setLoading(false);
          }
        } catch (e) {
          Store.onFailure(e);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    setup();
    console.log('6');
  }, [currentUser, location.path, location.search]);

  if (loading) {
    return <Loading />;
  }

  if (loginLink) {
    return <Redirect replace to={location.path || '/'} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser?.uid) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
