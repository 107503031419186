/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NavBar from './NavBar';
import CreateChannelMutation from '../mutations/CreateChannelMutation';
import Card from './Card';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },

  form: {
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(4),
  },

  buttons: {
    display: 'flex',
    marginTop: 20,
  },
});

class CreateChannel extends React.Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
    this.state = { redirect: null };
  }

  cancel(e) {
    e.preventDefault();
    return this.props.history.goBack();
  }

  submit(e) {
    e.preventDefault();
    const onSuccess = (resp) => {
      return this.setState({
        redirect: `/channels/${resp.createChannel.channelEdge.node.id}`,
      });
    };
    return CreateChannelMutation.commit(
      this.state.name,
      this.props.viewer,
      onSuccess,
    );
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className={classes.root}>
        <NavBar />
        <Card>
          <form className={classes.form} onSubmit={this.submit}>
            <Typography variant="h2"> Create a Channel</Typography>
            <TextField
              onChange={(e) => this.setState({ name: e.target.value })}
              autoFocus
              fullWidth
              label="name"
            />
            <div className={classes.buttons}>
              <Button type="button" onClick={this.cancel}>
                Cancel
              </Button>
              <div style={{ flex: 1 }} />
              <Button
                type="submit"
                color="primary"
                disabled={this.state.loading}
              >
                {this.state.loading ? 'Saving...' : 'Create'}
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CreateChannel);
