/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';
import Store from '../components/Store';

const mutation = graphql`
  mutation CreateChannelMutation($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channelEdge {
        node {
          id
          name
        }
      }
    }
  }
`;

const commit = function(name, viewer, onSuccess) {
  const variables = {
    input: {
      name,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    configs: [
      {
        type: 'RANGE_ADD',
        parentID: viewer.id,
        connectionInfo: [
          {
            key: 'Channels_channels',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'channelEdge',
      },
    ],
    onCompleted: onSuccess,
    onError: Store.onFailure,
  });
};

export default { commit };
