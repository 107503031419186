import firebase from '../firebaseApp';

class Store {
  constructor() {
    this.onFailure = this.onFailure.bind(this);
    this.data = {
      onUpdate: null,
      error: null,
      successMsg: null,
    };
  }

  onFailure(e) {
    this.data.error = e;
    console.log('error', e);
    return this.save();
  }

  setSuccess(msg) {
    this.data.successMsg = msg;
    return this.save();
  }

  async getToken() {
    //let localToken = localstorage.getItem('token');
    return new Promise(async (resolve, reject) => {
      let token = {};
      try {
        let currentUser = firebase.auth().currentUser;
        if (currentUser) {
          let tokenResult = await firebase
            .auth()
            .currentUser.getIdTokenResult();
          if (
            tokenResult &&
            new Date(tokenResult.expirationTime) <= Date.now()
          ) {
            token = await currentUser.getIdToken(true);
          } else {
            token = tokenResult.token;
          }
        } else {
          //token = localToken;
        }
      } catch (e) {
        if (e.code === 'auth/invalid-refresh') {
          console.log('invalid-refresh token');
          return;
        }
        this.onFailure(e);
      }
      this.cacheToken(token);
      return resolve(token);
    });
  }

  setCacheClear(clear) {
    this.data.cacheClear = clear;
  }

  cacheToken(token) {
    this.data.token = token;
  }

  getCachedToken() {
    return this.data.token;
  }

  async logout() {
    try {
      await firebase.auth().signOut();
    } catch (e) {
      console.log(e);
    }
    if (this.data.cacheClear) {
      this.data.cacheClear();
    }
    this.save();
    delete localStorage.token;
    this.data.loggedIn = false;
    return this.save();
  }

  async getHeaders() {
    const headers = {};

    const token = await this.getToken();
    if (token != null) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    return headers;
  }

  onUpdate(func) {
    return (this.data.onUpdate = func);
  }

  save() {
    if (!this.data.onUpdate) {
      return;
    }
    this.data.onUpdate();
    return true;
  }
}

export default new Store();
