import React from 'react';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import RelayEnv from '../RelayEnv';
import CallbackGraphiql from './CallbackGraphiql';

import QueryError from './QueryError';

export default function TeamCallbackGraphiQLContainer() {
  return (
    <QueryRenderer
      query={graphql`
        query TeamCallbackGraphiqlQuery {
          viewer {
            id
            team {
              id
              callbackQuery
            }
          }
        }
      `}
      environment={RelayEnv}
      variables={{}}
      render={({ props, retry, error }) => {
        if (error) {
          return <QueryError retry={retry} error={error} />;
        } else if (props) {
          return (
            <CallbackGraphiql
              callbackQuery={props.viewer.team.callbackQuery}
              {...props}
            />
          );
        } else {
          return null;
        }
      }}
    />
  );
}
