import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import firebase from '../firebaseApp';
//import 'firebase/auth';

const CurrentUserContext = createContext();
const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    let unregister = firebase.auth().onAuthStateChanged(
      (user) => {
        if (!user) user = {};
        // console.log('user', user);
        setCurrentUser(user);
        //localStorage.setItem('authUser', JSON.stringify(user));
      },
      () => {
        localStorage.removeItem('authUser');
        setCurrentUser(null);
      },
      //setCurrentUser(JSON.parse(localStorage.getItem('authUser'))),
    );
    return () => {
      unregister();
    };
  }, []);
  const value = useMemo(() => [currentUser, setCurrentUser], [
    currentUser,
    setCurrentUser,
  ]);

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
export { CurrentUserProvider };

const useCurrentUser = () => useContext(CurrentUserContext);
export default useCurrentUser;
