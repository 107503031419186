/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Linkify from 'react-linkify';

import Replies from './Replies';

import ReplyMutation from '../mutations/ReplyMutation';
import formatDate from '../formatDate';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  message: {
    margin: 5,
  },

  channel: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: 4,
    color: '#999',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  via: {
    marginLeft: 10,
  },
});

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.onSend = this.onSend.bind(this);
    this.state = {
      showAction: null,
      text: '',
    };
  }

  onAction(action) {
    return (e) => {
      console.log('action', action);
      if (action.kind === 'text') {
        return this.setState({
          showAction: action,
        });
      } else {
        return ReplyMutation.commit(null, action.id);
      }
    };
  }

  onSend(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    return ReplyMutation.commit(
      null,
      this.state.showAction.id,
      this.state.text,
      () => {
        return this.setState({
          loading: false,
          showAction: null,
          text: '',
        });
      },
    );
  }

  render() {
    const { classes, message } = this.props;

    return (
      <div className={classes.message}>
        <div className={classes.row}>
          <strong>{message.title}</strong>
          <br />
          <div className={classNames(classes.channel, classes.row)}>
            <React.Fragment>
              <div>{formatDate(message.createdAt)}</div>
              {this.props.showChannel && message.channel != null && (
                <div className={classes.via}>via {message.channel.name}</div>
              )}
              {message.receiver != null && (
                <div className={classes.via}>
                  sent to {message.receiver.fullName}
                </div>
              )}
            </React.Fragment>
          </div>
        </div>
        {message.body ? (
          <Linkify
            properties={{
              target: '_blank',
              style: { color: 'red', fontWeight: 'bold' },
            }}
          >
            {message.body.split('\n').map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </Linkify>
        ) : (
          undefined
        )}
        <Replies message={message} />
        {this.state.showAction ? (
          <form className={classes.row} name="send" onSubmit={this.onSend}>
            <TextField
              onChange={(e) => this.setState({ text: e.target.value })}
              autoFocus
              style={{ width: '240px' }}
            />
            <br />
            <Button color="primary" type="submit" disabled={this.state.loading}>
              Send
            </Button>
          </form>
        ) : (
          <div className={classes.row}>
            {Array.from(message.actions).map((action) => (
              <Button
                onClick={this.onAction(action)}
                color="primary"
                key={`${action.id}`}
              >
                {action.value}
              </Button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

Message = createFragmentContainer(Message, {
  message: graphql`
    fragment Message_message on Message {
      id
      title
      body
      createdAt
      ...Replies_message
      channel {
        id
        name
      }
      receiver {
        id
        fullName
      }
      actions {
        id
        value
        kind
      }
    }
  `,
});

export default withStyles(styles)(Message);
