/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
});

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { foo: 'bar' };
  }

  render() {
    //const { classes, theme } = this.props;
    console.log('response', this.props.response);
    return (
      <div>
        {this.props.response == null ? (
          <p>Select an action on the notification</p>
        ) : (
          undefined
        )}
        <div>
          {(() => {
            if (this.props.responseLoading) {
              return <CircularProgress />;
            } else if (this.props.response) {
              return (
                <div>
                  Response: {this.props.response}
                  <div>
                    You're all setup!
                    <Button color="primary" component={Link} to="/">
                      Go to account
                    </Button>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Step4);
