/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TeamCallbackGraphiqlQueryVariables = {||};
export type TeamCallbackGraphiqlQueryResponse = {|
  +viewer: {|
    +id: string,
    +team: {|
      +id: string,
      +callbackQuery: ?string,
    |},
  |}
|};
export type TeamCallbackGraphiqlQuery = {|
  variables: TeamCallbackGraphiqlQueryVariables,
  response: TeamCallbackGraphiqlQueryResponse,
|};
*/


/*
query TeamCallbackGraphiqlQuery {
  viewer {
    id
    team {
      id
      callbackQuery
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callbackQuery",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamCallbackGraphiqlQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TeamCallbackGraphiqlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TeamCallbackGraphiqlQuery",
    "operationKind": "query",
    "text": "query TeamCallbackGraphiqlQuery {\n  viewer {\n    id\n    team {\n      id\n      callbackQuery\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '110a6c5c76697fb39718ef8066d08d79';

module.exports = node;
