import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation UpdateTeamMutation($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        callbackUrl
        callbackQuery
      }
    }
  }
`;

const commit = function(callbackUrl, callbackQuery, onSuccess) {
  const variables = {
    input: {
      callbackUrl,
      callbackQuery,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: onSuccess,
  });
};

export default { commit };
