/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import Card from './Card';
import UpdateChannelMutation from '../mutations/UpdateChannelMutation';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  title: {
    margin: '0 0 16px',
  },

  buttons: {
    marginTop: 10,
    marginBottom: 10,
  },
});

class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.onSnackClose = this.onSnackClose.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      callbackUrl: props.channel.callbackUrl || 'https://',
      snackOpen: false,
    };
  }

  onSnackClose() {
    return this.setState({
      snackOpen: false,
    });
  }

  save(e) {
    e.preventDefault();
    return UpdateChannelMutation.commit(
      this.state.callbackUrl,
      null,
      this.props.channel.id,
      (resp) => {
        return this.setState({
          snackOpen: true,
        });
      },
    );
  }

  render() {
    const { classes, channel } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    if (this.state.successChannelId) {
      return <Redirect to={`/messages/${this.state.successChannelId}`} />;
    }

    return (
      <Card>
        <Typography variant="h2" className={classes.title}>
          {this.props.channel.name}
        </Typography>
        <Typography variant="body1">
          Channel ID: {this.props.channelId}
        </Typography>
        <Typography variant="body1">
          Specify a webhook endpoint to receive a POST request for each event.
          For more information refer to the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://pushback.io/docs/webhooks"
          >
            {' '}
            webhook docs
          </a>
        </Typography>
        <div>
          <TextField
            onChange={(e) => this.setState({ callbackUrl: e.target.value })}
            label="Webhook endpoint"
            value={this.state.callbackUrl}
            style={{ width: '240px' }}
          />
          <Button onClick={this.save} color="primary">
            Save
          </Button>
          {this.state.callbackUrl ? (
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: `/channel_callback/${channel.id}`,
              }}
            >
              Customize Webhook
            </Button>
          ) : (
            undefined
          )}
        </div>
        <br />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackOpen}
          onClose={this.onSnackClose}
          autoHideDuration={5000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Saved!</span>}
        />
      </Card>
    );
  }
}

Channel = createFragmentContainer(Channel, {
  channel: graphql`
    fragment Channel_channel on OwnedChannel {
      id
      joinedChannelId
      subscribed
      name
      callbackUrl
    }
  `,
});

export default withStyles(styles, { withTheme: true })(Channel);
