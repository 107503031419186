/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteInvitationInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type DeleteTeamInvitationMutationVariables = {|
  input: DeleteInvitationInput
|};
export type DeleteTeamInvitationMutationResponse = {|
  +deleteInvitation: ?{|
    +deletedId: string
  |}
|};
export type DeleteTeamInvitationMutation = {|
  variables: DeleteTeamInvitationMutationVariables,
  response: DeleteTeamInvitationMutationResponse,
|};
*/


/*
mutation DeleteTeamInvitationMutation(
  $input: DeleteInvitationInput!
) {
  deleteInvitation(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteInvitationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteInvitationPayload",
    "kind": "LinkedField",
    "name": "deleteInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTeamInvitationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTeamInvitationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DeleteTeamInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTeamInvitationMutation(\n  $input: DeleteInvitationInput!\n) {\n  deleteInvitation(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7a6127e82f44a3976c9c2e11fbf5c09';

module.exports = node;
