/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Replies_message$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Message_message$ref: FragmentReference;
declare export opaque type Message_message$fragmentType: Message_message$ref;
export type Message_message = {|
  +id: string,
  +title: string,
  +body: ?string,
  +createdAt: any,
  +channel: ?{|
    +id: string,
    +name: string,
  |},
  +receiver: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +actions: ?$ReadOnlyArray<{|
    +id: string,
    +value: string,
    +kind: string,
  |}>,
  +$fragmentRefs: Replies_message$ref,
  +$refType: Message_message$ref,
|};
export type Message_message$data = Message_message;
export type Message_message$key = {
  +$data?: Message_message$data,
  +$fragmentRefs: Message_message$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Message_message",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JoinedChannel",
      "kind": "LinkedField",
      "name": "channel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChannelUser",
      "kind": "LinkedField",
      "name": "receiver",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Action",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Replies_message"
    }
  ],
  "type": "Message"
};
})();
// prettier-ignore
(node/*: any*/).hash = '59961d88b3876c39bdc49d010be2107b';

module.exports = node;
