/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {requestSubscription} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {ConnectionHandler} from 'relay-runtime';

import environment from '../RelayEnv';

const subscription = graphql`
  subscription ReplySentSubscription {
    replySent {
      node {
        id
        ...Reply_reply
        message {
          id
        }
      }
    }
  }
`;

const subscribe = function(viewer, options) {
  if (options == null) {
    options = {};
  }
  return requestSubscription(environment, {
    subscription,
    variables: {},
    updater: store => {
      const payload = store.getRootField('replySent');
      const node = payload.getLinkedRecord('node');
      const message = node.getLinkedRecord('message');
      const messageProxy = store.get(message.getValue('id'));
      if (messageProxy != null) {
        const conn = ConnectionHandler.getConnection(
          messageProxy,
          'Replies_replies',
        );
        if (conn != null) {
          const newEdge = ConnectionHandler.createEdge(
            store,
            conn,
            node,
            'ReplyEdge',
          );
          return ConnectionHandler.insertEdgeAfter(conn, newEdge);
        }
      }
    },
    ...options,
  });
};

export default {subscribe};
