/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Graphiql_viewer$ref: FragmentReference;
declare export opaque type Graphiql_viewer$fragmentType: Graphiql_viewer$ref;
export type Graphiql_viewer = {|
  +id: string,
  +accessToken: {|
    +secret: string
  |},
  +$refType: Graphiql_viewer$ref,
|};
export type Graphiql_viewer$data = Graphiql_viewer;
export type Graphiql_viewer$key = {
  +$data?: Graphiql_viewer$data,
  +$fragmentRefs: Graphiql_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Graphiql_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessToken",
      "kind": "LinkedField",
      "name": "accessToken",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secret",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
// prettier-ignore
(node/*: any*/).hash = 'c2a0d2caeb7fa9f1a4835bef5744afc3';

module.exports = node;
