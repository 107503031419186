/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JoinChannel_invitation$ref = any;
export type JoinChannelQueryVariables = {|
  token: string
|};
export type JoinChannelQueryResponse = {|
  +invitation: {|
    +$fragmentRefs: JoinChannel_invitation$ref
  |}
|};
export type JoinChannelQuery = {|
  variables: JoinChannelQueryVariables,
  response: JoinChannelQueryResponse,
|};
*/


/*
query JoinChannelQuery(
  $token: String!
) {
  invitation(token: $token) {
    ...JoinChannel_invitation
    id
  }
}

fragment JoinChannel_invitation on Invitation {
  id
  teamName
  channel {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinChannelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invitation",
        "kind": "LinkedField",
        "name": "invitation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JoinChannel_invitation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinChannelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invitation",
        "kind": "LinkedField",
        "name": "invitation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teamName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JoinedChannel",
            "kind": "LinkedField",
            "name": "channel",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "JoinChannelQuery",
    "operationKind": "query",
    "text": "query JoinChannelQuery(\n  $token: String!\n) {\n  invitation(token: $token) {\n    ...JoinChannel_invitation\n    id\n  }\n}\n\nfragment JoinChannel_invitation on Invitation {\n  id\n  teamName\n  channel {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c05dd81ec26278d6936b39452bca7a9';

module.exports = node;
