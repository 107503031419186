/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import Button from '@material-ui/core/Button';

class Step1 extends React.Component {
  render() {
    const { onStep } = this.props;
    return (
      <React.Fragment>
        {isIOS && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://itunes.apple.com/app/id1343344602"
          >
            <img
              style={{ width: 200 }}
              src="img/badge-download-on-the-app-store.svg"
              alt="ios app"
            />
          </a>
        )}
        {isAndroid && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=io.pushback"
          >
            <img
              src="img/google-play-badge.png"
              style={{ width: 250 }}
              alt="Android app"
            />
          </a>
        )}
        {!isMobile && (
          <React.Fragment>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://itunes.apple.com/app/id1343344602"
            >
              <img
                style={{ marginBottom: 15, width: 200 }}
                src="img/badge-download-on-the-app-store.svg"
                alt="ios app"
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=io.pushback"
            >
              <img
                src="img/google-play-badge.png"
                style={{ width: 250 }}
                alt="Android app"
              />
            </a>
          </React.Fragment>
        )}
        <Button
          color="primary"
          onClick={() => {
            onStep(1);
          }}
        >
          Next
        </Button>
      </React.Fragment>
    );
  }
}

export default Step1;
