/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles } from '@material-ui/core/styles';

import PrivateRoute from './PrivateRoute';
import Login from './Login';
import LoginWithEmail from './LoginWithEmail';
import OpenOnMobile from './OpenOnMobile';
import JoinChannel from './JoinChannel';
import Main from './Main';
import QueryError from './QueryError';
import Store from './Store';
import Loading from './Loading';
import RelayEnv from '../RelayEnv';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
  },

  toolbar: theme.mixins.toolbar,
}));

export default function App(props) {
  const classes = useStyles();

  let [tick, setTick] = React.useState(0);

  let tickFun = () => {
    setTick(tick + 1);
  };

  Store.onUpdate(tickFun);

  return (
    <>
      <CssBaseline />

      <BrowserRouter className={classes.root}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/login_with_email" component={LoginWithEmail} />
          <Route path="/open_on_mobile" component={OpenOnMobile} />
          <Route path="/invite/:token" component={JoinChannel} />
          <PrivateRoute path="/">
            <QueryRenderer
              query={graphql`
                query AppQuery {
                  viewer {
                    error
                    id
                    ...Main_viewer
                  }
                }
              `}
              variables={{}}
              environment={RelayEnv}
              render={({ done, error, props, retry, stale }) => {
                if (error) {
                  return <QueryError retry={retry} error={error} />;
                } else if (props) {
                  return <Main {...props} />;
                } else {
                  return <Loading />;
                }
              }}
            />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </>
  );
}
