/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import NavBar from './NavBar';
import Card from './Card';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  toolbar: theme.mixins.toolbar,
});

class OpenOnMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { foo: 'bar' };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <NavBar />
        <div className={classes.toolbar} />
        <Card style={{ alignItems: 'center' }}>
          <h2>Open Link on your mobile device!</h2>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OpenOnMobile);
