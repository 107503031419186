/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReplyInput = {|
  actionId: string,
  clientMutationId?: ?string,
  messageId?: ?string,
  text?: ?string,
  value?: ?string,
|};
export type ReplyMutationVariables = {|
  input: ReplyInput
|};
export type ReplyMutationResponse = {|
  +reply: ?{|
    +replyEdge: {|
      +node: ?{|
        +id: string,
        +text: ?string,
      |}
    |},
    +clientMutationId: ?string,
  |}
|};
export type ReplyMutation = {|
  variables: ReplyMutationVariables,
  response: ReplyMutationResponse,
|};
*/


/*
mutation ReplyMutation(
  $input: ReplyInput!
) {
  reply(input: $input) {
    replyEdge {
      node {
        id
        text
      }
    }
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReplyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReplyPayload",
    "kind": "LinkedField",
    "name": "reply",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReplyEdge",
        "kind": "LinkedField",
        "name": "replyEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reply",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReplyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReplyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ReplyMutation",
    "operationKind": "mutation",
    "text": "mutation ReplyMutation(\n  $input: ReplyInput!\n) {\n  reply(input: $input) {\n    replyEdge {\n      node {\n        id\n        text\n      }\n    }\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efa6ebe1862178c55e13290551e290ef';

module.exports = node;
