import React from 'react';

import { createFragmentContainer, QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import QueryError from './QueryError';
import Card from './Card';
import RelayEnv from '../RelayEnv';
import DeleteTeamInvitationMutation from '../mutations/DeleteTeamInvitationMutation';
import CreateTeamInviteMutation from '../mutations/CreateTeamInviteMutation';
import UpdateTeamMutation from '../mutations/UpdateTeamMutation';

const useStyles = makeStyles((theme) => ({
  inviteHeader: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
}));

function SimpleDialog(props) {
  //const classes = useStyles();
  const { onClose, open } = props;
  const [email, setEmail] = React.useState('');

  let onInvite = (e) => {
    e.preventDefault();
    CreateTeamInviteMutation.commit(
      email,
      () => {
        onClose();
      },
      () => {
        onClose();
      },
    );
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Add Team Member</DialogTitle>
      <form onSubmit={onInvite}>
        <DialogContent>
          <DialogContentText>
            Please enter the email of a user you'd like to invite to your team.{' '}
            An email <strong>will not</strong> be sent to them. After clicking
            add, you'll need to send them the invite link.
          </DialogContentText>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            id="name"
            color="primary"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

let Team = ({ viewer }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);

  let callbackQ = viewer.team.callbackUrl
    ? viewer.team.callbackUrl
    : 'https://';
  const [callback, setCallback] = React.useState(callbackQ);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  let onRemove = (id) => (e) => {
    DeleteTeamInvitationMutation.commit(id);
  };

  let onWebhookSave = () => {
    UpdateTeamMutation.commit(callback, null, (resp) => {
      setSnackOpen(true);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <Typography variant="h2">Team Callback</Typography>
          <Typography variant="body2">
            Specify a webhook endpoint to receive a POST request for each event
            on your team. For more information refer to the{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://pushback.io/docs/webhooks"
            >
              {' '}
              webhook docs
            </a>
          </Typography>
          <div className={classes.webhook}>
            <TextField
              onChange={(e) => setCallback(e.target.value)}
              label="Webhook endpoint"
              value={callback}
              style={{ width: '240px' }}
            />
            <Button onClick={onWebhookSave} color="primary">
              Save
            </Button>
          </div>
          {viewer.team.callbackUrl && (
            <Button
              color="primary"
              component={Link}
              to={{
                pathname: '/team_callback',
              }}
            >
              Customize Webhook
            </Button>
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackOpen}
            onClose={() => setSnackOpen(false)}
            autoHideDuration={5000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Saved!</span>}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <div className={classes.inviteHeader}>
            <Typography variant="h2">New members invites</Typography>
            <Button color="primary" onClick={onOpen} style={{ marginLeft: 16 }}>
              Add
            </Button>
            <SimpleDialog open={open} onClose={onClose} />
          </div>
          <Typography>
            Send the invite link to users and have them sign in to pushback with
            the matching email to join your team.
          </Typography>
          {viewer.team.invitations.edges.map((edge) => {
            let invite = edge.node;
            return (
              <div key={invite.id} className={classes.row}>
                <IconButton id={invite.id} onClick={onRemove(invite.id)}>
                  <RemoveIcon />
                </IconButton>
                <div> {invite.email || ''}&nbsp;&nbsp; </div>
                <div style={{ wordBreak: 'break-all' }}>
                  <a href={invite.url}>{invite.url}</a>
                </div>
              </div>
            );
          })}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Typography variant="h2">Team Members</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {viewer.team.users.edges.map((edge) => {
                let user = edge.node;
                return (
                  <TableRow key={user.id}>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell> {user.email || ''}</TableCell>
                    <TableCell> {user.role} </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
};

Team = createFragmentContainer(Team, {
  viewer: graphql`
    fragment Team_viewer on User {
      id
      team {
        id
        callbackQuery
        callbackUrl
        invitations(first: 100) @connection(key: "Team_invitations") {
          edges {
            node {
              url
              email
              id
            }
          }
        }
        users(first: 100) @connection(key: "Team_users") {
          edges {
            node {
              id
              fullName
              email
              role
            }
          }
        }
      }
    }
  `,
});

class TeamView extends React.Component {
  render() {
    return (
      <QueryRenderer
        query={graphql`
          query TeamQuery {
            viewer {
              id
              ...Team_viewer
            }
          }
        `}
        environment={RelayEnv}
        variables={{}}
        render={({ error, retry, props }) => {
          if (error) {
            return <QueryError retry={retry} error={error} />;
          } else if (props) {
            return <Team viewer={props.viewer} {...props} />;
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export default TeamView;
