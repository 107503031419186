/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Card from './Card';
import Store from './Store';
import VerifyAccount from './VerifyAccount';
import { STRIPEKEY, PRODUCT } from '../const';
import useCurrentUser from './useCurrentUser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  subscribe: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

let Subscribe = ({ viewer, match, location }) => {
  const [currentUser] = useCurrentUser();

  React.useEffect(() => {
    let search = new URLSearchParams(location.search);
    let success = search.get('stripe_success');
    if (success) {
      Store.setSuccess("You've subscribed!");
    }
  }, [location.search]);

  let onCheckout = () => {
    let stripe = window.Stripe(STRIPEKEY);
    stripe
      .redirectToCheckout({
        items: [{ plan: PRODUCT, quantity: 1 }],

        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        successUrl: `${window.location.protocol}//${window.location.host}/subscribe?stripe_success=true`,
        cancelUrl: `${window.location.protocol}//${window.location.host}/subscribe`,
        customerEmail: currentUser.email,
      })
      .then(function(result) {
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer.
          return Store.onFailure(result.error.message);
        }
      });
  };

  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {currentUser.isAnonymous && (
          <Card>
            <Typography variant="h2">Finish Setup</Typography>
            <Typography>
              Choose an account provider before you can subscribe
            </Typography>
            <VerifyAccount
              message="In order to subscribe you must verify your email."
              params="redirect%3D%2Fsubscribe"
            />
          </Card>
        )}
      </Grid>
      {viewer.subscribed === 'limited' ? (
        <Grid item xs={12}>
          <Card>
            <Typography variant="h2">Subscribe</Typography>
            <Typography>
              You have used {viewer.messageCount} of your 100 free messages. For
              $12/year you'll get unlimited messages.
            </Typography>
            <div>
              <Button
                id="checkout-button-plan_FRtMPFn7UEkpvd"
                role="link"
                className={classes.subscribe}
                disabled={currentUser.isAnonymous}
                onClick={onCheckout}
                color="primary"
              >
                Subscribe
              </Button>
            </div>
            <Typography variant="body1">
              Your payments will be handled by redirecting to{' '}
              <a href="https://stripe.com">Stripe.com</a>. Your credit card
              details go through their secure payment system.
            </Typography>
            <div id="error-message"></div>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card>
            <Typography variant="h2">Subscribed</Typography>
            <Typography>
              You are currently subscribed and get unlimited messages. To
              unsubscribe email support@pushback.io
            </Typography>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

Subscribe = createFragmentContainer(Subscribe, {
  viewer: graphql`
    fragment Subscribe_viewer on User {
      id
      email
      subscribed
      messageCount
    }
  `,
});

export default Subscribe;
