/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateInvitationInput = {|
  channelId?: ?string,
  clientMutationId?: ?string,
  email?: ?string,
  kind: string,
|};
export type CreateInvitationMutationVariables = {|
  input: CreateInvitationInput
|};
export type CreateInvitationMutationResponse = {|
  +createInvitation: ?{|
    +invitationEdge: {|
      +node: ?{|
        +id: string,
        +kind: string,
        +url: string,
        +token: string,
      |}
    |}
  |}
|};
export type CreateInvitationMutation = {|
  variables: CreateInvitationMutationVariables,
  response: CreateInvitationMutationResponse,
|};
*/


/*
mutation CreateInvitationMutation(
  $input: CreateInvitationInput!
) {
  createInvitation(input: $input) {
    invitationEdge {
      node {
        id
        kind
        url
        token
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateInvitationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateInvitationPayload",
    "kind": "LinkedField",
    "name": "createInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvitationEdge",
        "kind": "LinkedField",
        "name": "invitationEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvitationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvitationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateInvitationMutation(\n  $input: CreateInvitationInput!\n) {\n  createInvitation(input: $input) {\n    invitationEdge {\n      node {\n        id\n        kind\n        url\n        token\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a48f213f34c23c3b5a01e32292555ca2';

module.exports = node;
