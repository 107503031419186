/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import {createPaginationContainer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Card from './Card';
import Message from './Message';

const styles = theme => ({
  root: {
    display: 'flex',
  },

  card: {
    backgroundColor: 'white',
    padding: 3,
    lineHeight: 1.5,
    minHeight: 1,
  },
});

class UserMessages extends React.Component {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {foo: 'bar'};
  }

  loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    return this.props.relay.loadMore(10, error => {
      return console.log(error);
    });
  }

  render() {
    const {classes} = this.props;
    if (this.props.viewer.messages.edges.length === 0) {
      return null;
    }

    return (
      <Card classes={{root: classes.card}}>
        {this.props.viewer.messages.edges.map(edge => {
          return <Message key={edge.node.id} showChannel message={edge.node} />;
        })}
        {this.props.relay.hasMore() ? (
          <Button color="primary" onClick={this.loadMore}>
            {' '}
            Load More{' '}
          </Button>
        ) : (
          undefined
        )}
      </Card>
    );
  }
}

UserMessages = createPaginationContainer(
  UserMessages,
  {
    viewer: graphql`
      fragment UserMessages_viewer on User
        @argumentDefinitions(
          count: {type: "Int", defaultValue: 10}
          cursor: {type: "String"}
        ) {
        id
        messages(first: $count, after: $cursor)
          @connection(key: "UserMessages_messages") {
          edges {
            node {
              id
              ...Message_message
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.messages;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
      };
    },
    // userID isn't specified as an @argument for the fragment, but it should be a variable available for the fragment under the query root.
    query: graphql`
      # Pagination query to be fetched upon calling \`loadMore\`.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query UserMessagesPaginationQuery($count: Int!, $cursor: String) {
        viewer: viewer {
          ...UserMessages_viewer @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);

export default withStyles(styles)(UserMessages);
