/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import {createPaginationContainer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Card from './Card';
import Message from './Message';
import ProcessInvitationMutation from '../mutations/ProcessInvitationMutation';

const styles = theme => ({
  root: {
    display: 'flex',
  },

  card: {
    backgroundColor: 'white',
    padding: 3,
    lineHeight: 1.5,
    minHeight: 1,
  },
});

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.joinChannel = this.joinChannel.bind(this);
    this.state = {foo: 'bar'};
  }

  loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    return this.props.relay.loadMore(10, error => {
      return console.log(error);
    });
  }

  joinChannel(e) {
    return ProcessInvitationMutation.commit(
      this.props.viewer.id,
      this.props.channel.id,
      resp => {
        return this.setState({
          successChannelId: resp.processInvitation.channelEdge.node.id,
          loading: false,
        });
      },
    );
  }

  render() {
    const {classes} = this.props;
    if (
      (this.props.channel.joined != null
        ? this.props.channel.joined.messages.edges.length
        : undefined) === 0
    ) {
      return <div>No Messages</div>;
    }

    return (
      <Card classes={{root: classes.card}}>
        {this.props.channel.joined != null ? (
          <React.Fragment>
            {this.props.channel.joined.messages.edges.map(edge => {
              return <Message key={edge.node.id} message={edge.node} />;
            })}
            {this.props.relay.hasMore() ? (
              <Button color="primary" onClick={this.loadMore}>
                {' '}
                Load More{' '}
              </Button>
            ) : (
              undefined
            )}
          </React.Fragment>
        ) : (
          <div className={classes.buttons}>
            <Button onClick={this.joinChannel} color="primary">
              Join Channel
            </Button>
          </div>
        )}
      </Card>
    );
  }
}

Messages = createPaginationContainer(
  Messages,
  {
    channel: graphql`
      fragment Messages_channel on Channel
        @argumentDefinitions(
          count: {type: "Int", defaultValue: 10}
          cursor: {type: "String"}
        ) {
        id
        joined {
          id
          messages(first: $count, after: $cursor)
            @connection(key: "Messages_messages") {
            edges {
              node {
                id
                ...Message_message
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return __guard__(
        props.channel != null ? props.channel.joined : undefined,
        x => x.messages,
      );
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        // userID isn't specified as an @argument for the fragment, but it should be a variable available for the fragment under the query root.
        channelID: props.channel.id,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling \`loadMore\`.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query MessagesPaginationQuery(
        $count: Int!
        $cursor: String
        $channelID: ID!
      ) {
        channel: node(id: $channelID) {
          ...Messages_channel @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);

export default withStyles(styles)(Messages);

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
