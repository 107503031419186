import React from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import useCurrentUser from './useCurrentUser';

import Toast from './Toast';
import Store from './Store';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
  },

  flex: {
    flex: 1,
    textDecoration: 'none',
  },

  link: {
    textDecoration: 'none',
    boxShadow: 'none',
    color: 'white',
  },

  menuItem: {
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'rgb(0,0,0,0.87)',
    textDecoration: 'none',
  },

  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginLeft: -12,
  },

  moreButton: {
    color: 'white',
  },

  toolbar: theme.mixins.toolbar,
}));

export default function NavBar({ drawerToggle }) {
  let [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  let handleClose = () => {
    setAnchorEl(null);
  };

  let logout = () => {
    handleClose();
    return Store.logout();
  };

  const [currentUser] = useCurrentUser();

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {drawerToggle && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              className={classes.navIconHide}
            >
              <Icon>menu</Icon>
            </IconButton>
          )}
          <Typography
            component={Link}
            to="/"
            className={classes.flex}
            variant="h6"
            color="inherit"
            noWrap
          >
            Pushback
          </Typography>
          <Hidden smDown implementation="css">
            {currentUser?.uid ? (
              <React.Fragment>
                <Button component={Link} to="/profile" color="inherit">
                  Profile
                </Button>
                <a
                  className={classes.link}
                  href="https://pushback.io/docs/getting-started/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button style={{ boxShadow: 'none' }} color="inherit">
                    Docs
                  </Button>
                </a>
                <Button color="inherit" onClick={logout}>
                  Logout
                </Button>
              </React.Fragment>
            ) : (
              <Button component={Link} to="/login" color="inherit">
                Login
              </Button>
            )}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.moreButton}
              aria-label="More"
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Icon>more_vert_icon</Icon>
            </IconButton>
            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {currentUser?.uid ? (
                [
                  <MenuItem
                    key={1}
                    component={Link}
                    to="/profile"
                    onClick={handleClose}
                  >
                    Profile
                  </MenuItem>,
                  <MenuItem key={2} onClick={handleClose}>
                    <a
                      href="https://pushback.io/docs/getting-started/"
                      className={classes.menuItem}
                    >
                      Docs
                    </a>
                  </MenuItem>,
                  <MenuItem key={3} onClick={logout}>
                    Logout
                  </MenuItem>,
                ]
              ) : (
                <MenuItem component={Link} to="/login" onClick={handleClose}>
                  Login
                </MenuItem>
              )}
            </Menu>
          </Hidden>
        </Toolbar>
        {Store.data.successMsg != null ? (
          <Toast
            color="green"
            message={Store.data.successMsg}
            drawerWidth={drawerWidth}
          />
        ) : (
          undefined
        )}
        {Store.data.error != null ? (
          <Toast
            color="red"
            message={`Error: ${Store.data.error}`}
            drawerWidth={drawerWidth}
          />
        ) : (
          undefined
        )}
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
}
