/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {|
  callbackQuery?: ?string,
  callbackUrl?: ?string,
  clientMutationId?: ?string,
  name?: ?string,
|};
export type UpdateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type UpdateTeamMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +id: string,
      +callbackUrl: ?string,
      +callbackQuery: ?string,
    |}
  |}
|};
export type UpdateTeamMutation = {|
  variables: UpdateTeamMutationVariables,
  response: UpdateTeamMutationResponse,
|};
*/


/*
mutation UpdateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      id
      callbackUrl
      callbackQuery
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTeamPayload",
    "kind": "LinkedField",
    "name": "updateTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callbackUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callbackQuery",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      id\n      callbackUrl\n      callbackQuery\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '567b39c612432b5a340c72175c169a53';

module.exports = node;
