/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { ConnectionHandler } from 'relay-runtime';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation ProcessInvitationMutation($input: ProcessInvitationInput!) {
    processInvitation(input: $input) {
      channelEdge {
        node {
          id
          owned {
            id
          }
          ...ChannelView_channel
        }
      }
      userEdge {
        node {
          id
          fullName
          email
        }
      }
      clientMutationId
    }
  }
`;

const commit = function(viewerID, invitationToken, onSuccess, onError) {
  const variables = {
    input: {
      invitationToken,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    updater: (store) => {
      const payload = store.getRootField('processInvitation');
      const node = payload
        .getLinkedRecord('channelEdge')
        .getLinkedRecord('node');
      const viewerProxy =
        store.getRoot().getLinkedRecord('viewer') || store.get(viewerID);
      console.log('node', node);
      // TODO owned? possible bug??????
      if (viewerProxy && !node.getLinkedRecord('owned')) {
        const conn = ConnectionHandler.getConnection(
          viewerProxy,
          'Channels_channels',
        );
        if (conn) {
          const newEdge = ConnectionHandler.createEdge(
            store,
            conn,
            node,
            'ChannelEdge',
          );
          ConnectionHandler.insertEdgeAfter(conn, newEdge);
        }
      }
    },
    onCompleted: onSuccess,
    onError,
  });
};

export default { commit };
