/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';

import Store from './Store';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,

  container: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
  },

  message: {
    padding: theme.spacing(1),
    color: 'white',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
  },
});

class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.clear = this.clear.bind(this);
    this.state = { foo: 'bar' };
  }

  clear() {
    Store.data.error = null;
    Store.data.successMsg = null;
    return Store.save();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.toolbar} />
        <div
          style={{ backgroundColor: this.props.color }}
          className={classes.message}
        >
          <div className={classes.row}>
            <div>{this.props.message}</div>
            <div style={{ flex: 1 }} />
            <IconButton onClick={this.clear} style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Toast);
