import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation ReplyMutation($input: ReplyInput!) {
    reply(input: $input) {
      replyEdge {
        node {
          id
          text
        }
      }
      clientMutationId
    }
  }
`;

const commit = function(viewerID, actionId, text, onSuccess, onFailure) {
  const variables = {
    input: {
      actionId,
      text,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: onSuccess,
    onError: onFailure,
  });
};

export default { commit };
