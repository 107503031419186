/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { createFragmentContainer, QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import QueryError from './QueryError';
import NavBar from './NavBar';
import Card from './Card';
import ProcessInvitationMutation from '../mutations/ProcessInvitationMutation';
import RelayEnv from '../RelayEnv';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 30px)',
    alignItems: 'center',
    padding: 15,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class JoinChannel extends React.Component {
  constructor(props) {
    super(props);
    this.join = this.join.bind(this);
    this.state = { redirect: null };
  }

  join(e) {
    e.preventDefault();

    const onSuccess = (resp) => {
      let id = resp.processInvitation.channelEdge.node.id;
      let redirect = `/channels/${id}`;
      if (id === 'Channel_team') {
        redirect = '/';
      }

      return this.setState({
        redirect,
        loading: false,
      });
    };

    ProcessInvitationMutation.commit(null, this.props.token, onSuccess, () => {
      return this.setState({
        loading: false,
      });
    });

    return this.setState({
      loading: true,
    });
  }

  render() {
    const { classes, invitation } = this.props;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className={classes.root}>
        <NavBar />
        <Card>
          <form onSubmit={this.join}>
            {invitation.teamName ? (
              <>
                <h4>Join Team</h4>
                <Typography>
                  You have been invited to join {invitation.teamName}. Joining
                  allows you to receive notifications from the owner.
                </Typography>
              </>
            ) : (
              <>
                <h4>Join Channel</h4>
                <Typography>
                  You have been invited to join {invitation.channel?.name}.
                  Joining allows you to receive notifications from the owner.
                  You can leave the channel at any time.
                </Typography>
              </>
            )}
            <div className={classes.buttons}>
              <Button
                type="submit"
                color="primary"
                disabled={this.state.loading}
              >
                Join
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

JoinChannel = withStyles(styles, { withTheme: true })(
  createFragmentContainer(JoinChannel, {
    invitation: graphql`
      fragment JoinChannel_invitation on Invitation {
        id
        teamName
        channel {
          id
          name
        }
      }
    `,
  }),
);

class JoinChannelContainer extends React.Component {
  render() {
    let token;
    if (this.props.match) {
      ({ token } = this.props.match.params);
    }

    return (
      <QueryRenderer
        query={graphql`
          query JoinChannelQuery($token: String!) {
            invitation(token: $token) {
              ...JoinChannel_invitation
            }
          }
        `}
        variables={{ token }}
        environment={RelayEnv}
        render={({ error, retry, props }) => {
          if (error) {
            return <QueryError retry={retry} error={error} />;
          } else if (props) {
            return <JoinChannel {...this.props} {...props} token={token} />;
          } else {
            return null;
          }
        }}
      />
    );
  }
}

export default JoinChannelContainer;
