/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import {createPaginationContainer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Reply from './Reply';

const styles = theme => ({
  root: {
    marginLeft: 10,
  },

  card: {
    backgroundColor: 'white',
    padding: '0 15px 15px',
    lineHeight: 1.5,
    minHeight: 115,
  },
});

class Replies extends React.Component {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {foo: 'bar'};
  }

  loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    return this.props.relay.loadMore(10, error => {
      return console.error(error);
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        {this.props.relay.hasMore() ? (
          <Button onClick={this.loadMore}> Load More </Button>
        ) : (
          undefined
        )}
        {this.props.message.replies.edges.map(edge => {
          return <Reply reply={edge.node} key={edge.node.id} />;
        })}
      </div>
    );
  }
}

Replies = createPaginationContainer(
  Replies,
  {
    message: graphql`
      fragment Replies_message on Message
        @argumentDefinitions(
          count: {type: "Int", defaultValue: 10}
          cursor: {type: "String"}
        ) {
        id
        replies(last: $count, before: $cursor)
          @connection(key: "Replies_replies") {
          edges {
            node {
              id
              ...Reply_reply
            }
          }
        }
      }
    `,
  },
  {
    direction: 'backward',
    getConnectionFromProps(props) {
      return props.message && props.message.replies;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        // userID isn't specified as an @argument for the fragment, but it should be a variable available for the fragment under the query root.
        messageID: props.message.id,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling \`loadMore\`.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query RepliesPaginationQuery(
        $count: Int!
        $cursor: String
        $messageID: ID!
      ) {
        message: node(id: $messageID) {
          ...Replies_message @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);

export default withStyles(styles)(Replies);
