import moment from 'moment';

let locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);
let localeData = moment.localeData();
let timeFormat = localeData.longDateFormat('LTS');
let format = localeData.longDateFormat('ll');
format = format.replace(/.YYYY/, '');

export default function formatDate(date) {
  date = moment(date).local();
  if (date.isSame(new Date(), 'day')) {
    return date.format(timeFormat);
  } else {
    return date.format(`${format} ${timeFormat}`);
  }
}

export function formatReplyDate(date) {
  return moment(date)
    .local()
    .fromNow();
}
