import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import GraphiQL from 'graphiql';
import 'graphiql/graphiql.min.css';
import { parse, print } from 'graphql/language';
import { print as onelinePrinter } from '../graphql_printer';
import '../../node_modules/graphiql/graphiql.css';
import './callback_graphiql.css';

import UpdateChannelMutation from '../mutations/UpdateChannelMutation';
import UpdateTeamMutation from '../mutations/UpdateTeamMutation';
import { WEBHOST } from '../const';
import Card from './Card';
import Store from './Store';

const graphQLFetcher = async function(graphQLParams) {
  const headers = await Store.getHeaders();
  headers['Content-Type'] = 'application/json';
  return fetch(`${WEBHOST}/callback_graphql`, {
    method: 'post',
    headers,
    body: JSON.stringify(graphQLParams),
  })
    .then((response) => response.json())
    .catch((err) => {
      return console.error(err);
    });
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

class CallbackGraphiql extends React.Component {
  constructor(props) {
    super(props);
    this.formattedQuery = this.formattedQuery.bind(this);
    this.onPrettify = this.onPrettify.bind(this);
    this.onQueryEdit = this.onQueryEdit.bind(this);
    this.onVarsEdit = this.onVarsEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSnackClose = this.onSnackClose.bind(this);
    this.state = {
      showCurl: true,
      query: this.formattedQuery(),
      variables: this.variables(),
      snackOpen: false,
    };
  }

  formattedQuery() {
    let query = `{ \
messageSent { id, title, body, channel { id }, sender { id } } \
replySent { id, user { id, fullName } text, message { id, title, body } } \
}`;
    if (this.props.callbackQuery != null) {
      query = this.props.callbackQuery;
    }

    return print(parse(query));
  }

  variables(pretty) {
    return '{}';
  }

  onPrettify(event) {
    const editor = this.graphiql.getQueryEditor();
    return editor.setValue(print(parse(editor.getValue())));
  }

  onQueryEdit(q) {
    return this.setState({
      query: q,
    });
  }

  onVarsEdit(v) {
    return this.setState({
      curlVariables: JSON.stringify(JSON.parse(v)),
    });
  }

  onSave(v) {
    if (this.props.channelId) {
      UpdateChannelMutation.commit(
        null,
        onelinePrinter(parse(this.state.query)),
        this.props.channelId,
        () => {
          this.setState({
            snackOpen: true,
          });
        },
      );
    } else {
      UpdateTeamMutation.commit(
        null,
        onelinePrinter(parse(this.state.query)),
        () => {
          this.setState({
            snackOpen: true,
          });
        },
      );
    }
  }

  onSnackClose() {
    return this.setState({
      snackOpen: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Card>
          Edit the query below to customize what the webhook will post. Press
          the play button to see a sample response. Hit save when done
          customizing the query. For more information refer to the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://pushback.io/docs/webhooks"
          >
            webhook docs
          </a>
          .
        </Card>
        <GraphiQL
          ref={(c) => {
            return (this.graphiql = c);
          }}
          fetcher={graphQLFetcher}
          query={this.state.query}
          variables={this.state.variables}
          onEditQuery={this.onQueryEdit}
          onEditVariables={this.onVarsEdit}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={this.onPrettify}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button onClick={this.onSave} label="Save" title="Save" />
          </GraphiQL.Toolbar>
        </GraphiQL>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.snackOpen}
          onClose={this.onSnackClose}
          autoHideDuration={5000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Saved!</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CallbackGraphiql);
