/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { Link, NavLink } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import StarIcon from '@material-ui/icons/Star';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';

const drawerWidth = 240;

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.dark,
    borderRight: 0,
  },

  list: {
    backgroundColor: 'white',
    padding: 0,
  },

  listItem: {
    padding: 12,
  },

  noHighlight: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  listIcon: {
    color: theme.palette.primary.main,
  },

  listIconLeft: {
    paddingLeft: 4,
    paddingRight: 0,
    color: theme.palette.primary.main,
  },

  listTextNoIcon: {
    paddingLeft: 40,
  },

  listText: {
    padding: 0,
  },

  selected: {
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    color: theme.palette.secondary.contrastText,
  },
});

class Channels extends React.Component {
  constructor(...args) {
    super(...args);
    this.channelList = this.channelList.bind(this);
  }

  channelList(allChannels) {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {allChannels.map((edge) => {
          return (
            <ListItem
              key={edge.node.id}
              className={classes.listItem}
              component={React.forwardRef((props, ref) => (
                <NavLink {...props} />
              ))}
              to={`/channels/${edge.node.id}`}
              exact
              button
              activeClassName={classes.selected}
              onClick={this.props.drawerToggle}
            >
              <ListItemText
                primary={edge.node.name}
                classes={{
                  primary: classes.listTextNoIcon,
                }}
              />
            </ListItem>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div>
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            borderRight: 0,
          }}
          className={classes.toolbar}
        />
        <List className={classes.list}>
          <ListItem
            className={classes.listItem}
            component={React.forwardRef((props, ref) => (
              <NavLink {...props} />
            ))}
            to="/"
            exact
            button
            activeClassName={classes.selected}
            onClick={this.props.drawerToggle}
          >
            <ListItemIcon className={classes.listIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Home" />
          </ListItem>
          {this.props.viewer.role !== 'limited' && (
            <ListItem
              className={classes.listItem}
              component={React.forwardRef((props, ref) => (
                <NavLink {...props} />
              ))}
              to="/team"
              exact
              button
              activeClassName={classes.selected}
              onClick={this.props.drawerToggle}
            >
              <ListItemIcon className={classes.listIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Team" />
            </ListItem>
          )}
          {this.props.viewer.subscribed === 'limited' ? (
            <ListItem
              className={classes.listItem}
              component={React.forwardRef((props, ref) => (
                <NavLink {...props} />
              ))}
              to="/subscribe"
              exact
              button
              activeClassName={classes.selected}
              onClick={this.props.drawerToggle}
            >
              <ListItemIcon className={classes.listIcon}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Subscribe" />
            </ListItem>
          ) : (
            undefined
          )}
          <ListItem
            className={classnames(classes.listItem, classes.noHighlight)}
          >
            <ListItemIcon className={classes.listIcon}>
              <StarIcon />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Channels" />
            <ListItemSecondaryAction>
              <Link to="/channel/create">
                <IconButton aria-label="Add">
                  <AddIcon className={classes.listIcon} />
                </IconButton>
              </Link>
            </ListItemSecondaryAction>
          </ListItem>
          {this.channelList(this.props.viewer.channels.edges)}
        </List>
        <List></List>
      </div>
    );

    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.props.mobileOpen}
            onClose={this.props.drawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Channels = createFragmentContainer(Channels, {
  viewer: graphql`
    fragment Channels_viewer on User {
      subscribed
      role
      channels(first: 100) @connection(key: "Channels_channels") {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});

export default withStyles(styles, { withTheme: true })(Channels);
