/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';

import Card from './Card';
import CreateInvitationMutation from '../mutations/CreateInvitationMutation';
import DeleteInvitationMutation from '../mutations/DeleteInvitationMutation';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },

  title: {
    margin: '16px 0 16px',
    flex: 1,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  card: {
    backgroundColor: 'white',
    padding: '0 15px 15px',
    lineHeight: 1.5,
    minHeight: 115,
  },
});

class Invitations extends React.Component {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.state = { foo: 'bar' };
  }

  onRemove(id) {
    return (e) => {
      return DeleteInvitationMutation.commit(id, this.props.channel.id);
    };
  }

  onCreate() {
    return CreateInvitationMutation.commit(this.props.channel.id, 'URL');
  }

  invitations() {
    const { classes } = this.props;
    return this.props.channel.invitations.edges.map((edge) => {
      if (edge.node != null) {
        return (
          <div key={edge.node.id} className={classes.row}>
            <IconButton id={edge.node.id} onClick={this.onRemove(edge.node.id)}>
              <RemoveIcon />
            </IconButton>
            <div> {edge.node.email} </div>
            <div style={{ wordBreak: 'break-all' }}>
              <a href={edge.node.url}>{edge.node.url}</a>
            </div>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Card classes={{ root: classes.card }}>
        <div className={classes.row}>
          <Typography variant="h2" className={classes.title}>
            Invitations
          </Typography>
          {this.props.relay.isLoading ? <div>Loading</div> : undefined}
          <Button onClick={this.onCreate} color="primary">
            Create
          </Button>
        </div>
        <Typography variant="body1">
          Channels allow notifications to be sent to multiple users. To invite
          users to join a channel create and send them an invitation link.
        </Typography>
        {this.invitations()}
      </Card>
    );
  }
}

Invitations = createFragmentContainer(Invitations, {
  channel: graphql`
    fragment Invitations_channel on OwnedChannel {
      id
      invitations(first: 100) @connection(key: "Invitations_invitations") {
        edges {
          node {
            id
            kind
            email
            url
          }
        }
      }
    }
  `,
});

export default withStyles(styles, { withTheme: true })(Invitations);
