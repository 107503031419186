import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import Card from './Card';
import SimpleCurl from './SimpleCurl';
import UserMessages from './UserMessages';
import useCurrentUser from './useCurrentUser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  title: {
    margin: '0 0 16px',
  },
}));

let Home = ({ viewer }) => {
  const classes = useStyles();
  const hasDevice = viewer.devices.edges.length > 0;
  const [currentUser] = useCurrentUser();

  return (
    <Grid className={classes.root} container spacing={2}>
      {viewer.subscribed === 'limited' && viewer.messageCount >= 1 && (
        <Grid item sm={6} xs={12}>
          <Card>
            You've used {viewer.messageCount} of your {viewer.freeLimit} free
            messages.
            <div>
              <Button component={Link} to="/subscribe" color="primary">
                Upgrade now
              </Button>
            </div>
          </Card>
        </Grid>
      )}
      {(!hasDevice || currentUser.isAnonymous) && (
        <Grid item sm={6} xs={12}>
          <Card>
            <div>
              Finish setting up your account and devices
              <div>
                <Button component={Link} to="/finish_setup" color="primary">
                  Finish setup
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <Typography variant="h2" className={classes.title}>
            Send Notification
          </Typography>
          <SimpleCurl accessToken={viewer.accessToken.secret} id={viewer.id} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <UserMessages viewer={viewer} />
      </Grid>
    </Grid>
  );
};

Home = createFragmentContainer(Home, {
  viewer: graphql`
    fragment Home_viewer on User {
      id
      email
      messageCount
      freeLimit
      subscribed
      accessToken {
        secret
      }
      ...Profile_viewer
      ...UserMessages_viewer
      devices(first: 100) @connection(key: "Home_devices") {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});

export default Home;
