/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppleIcon from '@material-ui/icons/Apple';
import EmailIcon from '@material-ui/icons/Email';
import { Link } from 'react-router-dom';

import Card from './Card';

import firebase from 'firebase/app';
import Store from './Store';
import GoogleIcon from './GoogleIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  title: {
    margin: '0 0 16px',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    width: 250,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const AppleButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
}))(Button);

function VerifyAccount() {
  let [done, setDone] = React.useState(false);
  let onApple = async () => {
    try {
      var provider = new firebase.auth.OAuthProvider('apple.com');
      let currentUser = firebase.auth().currentUser;
      await currentUser.linkWithPopup(provider);
      // HACK: firebase is dumb
      window.location.reload();
      setDone(true);
    } catch (e) {
      Store.onFailure(e);
    }
  };

  let onGoogle = async () => {
    try {
      let provider = new firebase.auth.GoogleAuthProvider();

      let currentUser = firebase.auth().currentUser;
      await currentUser.linkWithPopup(provider);
      // HACK: firebase is dumb
      window.location.reload();
      /*
      firebase
        .auth()
        .signInWithCredential(
          firebase.auth.GoogleAuthProvider.credential(
            '{"sub": "abc123", "email": "foo@example.com", "email_verified": true}',
          ),
        );
        */
      setDone(true);
    } catch (e) {
      Store.onFailure(e);
    }
  };

  const classes = useStyles();
  if (done) {
    return null;
  }

  return (
    <Card>
      <div className={classes.buttons}>
        <AppleButton
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AppleIcon />}
          onClick={onApple}
        >
          Continue with Apple
        </AppleButton>
        <AppleButton
          variant="contained"
          color="primary"
          component={Link}
          to="/login_with_email"
          className={classes.button}
          startIcon={<EmailIcon />}
        >
          Continue with Email
        </AppleButton>

        <AppleButton
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<GoogleIcon />}
          onClick={onGoogle}
        >
          Continue with Google
        </AppleButton>
      </div>
    </Card>
  );
}

export default VerifyAccount;
