/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppleIcon from '@material-ui/icons/Apple';
import EmailIcon from '@material-ui/icons/Email';

import NavBar from './NavBar';
import Card from './Card';
import Store from './Store';
import firebase from 'firebase/app';
import GoogleIcon from './GoogleIcon';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },

  toolbar: theme.mixins.toolbar,

  card: {
    padding: 30,
    minWidth: 500,
  },

  form: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tryItOut: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(10),
  },

  title: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    width: 250,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const AppleButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
}))(Button);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { appleLoading: false, googleLoading: false };
  }

  handleInvite = async (e) => {
    // TODO
  };

  onAnonymous = async () => {
    this.setState({
      loading: true,
    });
    try {
      await firebase.auth().signInAnonymously();
      console.log('User signed in anonymously');
      this.handleInvite();
      this.setState({ redirectToReferrer: true });
    } catch (e) {
      Store.onFailure(e);
    }
  };

  onApple = async () => {
    this.setState({
      appleLoading: true,
    });
    try {
      var provider = new firebase.auth.OAuthProvider('apple.com');
      await firebase.auth().signInWithPopup(provider);
      console.log('Apple sign-in complete!');
      this.handleInvite();
      this.setState({ redirectToReferrer: true });
    } catch (e) {
      Store.onFailure(e);
    }
  };

  onGoogle = async () => {
    this.setState({
      googleLoading: true,
    });
    try {
      let provider = new firebase.auth.GoogleAuthProvider();

      await firebase.auth().signInWithPopup(provider);
      console.log('Google sign-in complete!');
      this.handleInvite();
      this.setState({ redirectToReferrer: true });
    } catch (e) {
      Store.onFailure(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div className={classes.root}>
        <NavBar />
        <div className={classes.toolbar} />
        <Card className={classes.card}>
          <div className={classes.title}>
            <Typography component="h1" variant="h5">
              Welcome to Pushback
            </Typography>
          </div>
          {from && from.pathname !== '/' && (
            <div className="login-warn">
              <p>
                You must log in to view the page at
                <code> {from.pathname}</code>
              </p>
            </div>
          )}
          <div className={classes.buttons}>
            <AppleButton
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<AppleIcon />}
              onClick={this.onApple}
            >
              Sign in with Apple
            </AppleButton>
            <AppleButton
              variant="contained"
              color="primary"
              component={Link}
              to="/login_with_email"
              className={classes.button}
              startIcon={<EmailIcon />}
            >
              Sign in with Email
            </AppleButton>

            <AppleButton
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<GoogleIcon />}
              onClick={this.onGoogle}
            >
              Sign in with Google
            </AppleButton>
            <AppleButton
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.onAnonymous}
            >
              Try it out, sign up later
            </AppleButton>
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
