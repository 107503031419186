/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import SimpleCurl from '../SimpleCurl';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
});

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { foo: 'bar' };
  }

  render() {
    // const { classes, theme } = this.props;
    return (
      <div>
        <SimpleCurl
          onResponse={this.props.onResponse}
          onSend={this.props.onSend}
          accessToken={this.props.viewer.accessToken.secret}
          id={this.props.viewer.id}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Step3);
