/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import environment from '../RelayEnv';

const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      statusMsg
    }
  }
`;

const commit = function(fullName, email, params, onSuccess) {
  const variables = {
    input: {
      fullName,
      email,
      params,
    },
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: onSuccess,
  });
};

export default { commit };
